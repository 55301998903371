import React, {useEffect, useState, useRef} from 'react';
import styles from '../Styles/userDetails.module.scss';
import { useTranslation } from 'react-i18next';
import { FiCheckCircle, FiEdit2, FiTable, FiUser, FiX, } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { AnimatePresence, motion } from 'framer-motion';
import cx from 'classnames';
import { Doughnut } from 'react-chartjs-2';
import { Gantt, ViewMode } from 'gantt-task-react';
import "gantt-task-react/dist/index.css";

import SideModal from './SideModal';
import { TaskModal } from './Overview';
import Modal from './Modal';


function EditModal(){
  
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t } = useTranslation();
  const [isEmailValide, setIsEmailValide] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  const onSubmit = (data)=>{
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: true,
      password: isEditPassword ? data.password : null,
      role: data.role
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));
    
    setUserCreated(true)
    setLoading(false);
    
  }


  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("USERS.MODAL.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[35vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex items-center justify-between gap-3 mb-3' >
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.LAST_NAME")} *</label>
              { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input 
              className='border rounded-lg px-2 py-1.5 text-sm' 
              placeholder={t("USERS.MODAL.LAST_NAME")}
              {...register("lastName", { required: true })} 
            />
          </div>
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.FIRST_NAME")} *</label>
              { errors.firstName && <label className='text-xs text-red-700 font-semibold truncate max-w-[40%]' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input 
              className='border rounded-lg px-2 py-1.5 text-sm' 
              placeholder={t("USERS.MODAL.FIRST_NAME")}
              {...register("firstName", { required: true })} 
            />
          </div>
        </div>
        <div className='flex flex-col w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.ROLE")} *</label>
            { errors.role && <label className='text-xs text-red-500 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input 
            className='border rounded-lg px-2 py-1.5 text-sm' 
            placeholder={t("USERS.MODAL.ROLE")}
            {...register("role", { required: true })} 
          />
        </div>
        <div className='border p-2 rounded-lg' >
          <div className='flex items-center justify-between' >
            <p className='text-gray-500 text-sm font-semibold' >{t("USERS.MODAL.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={()=> setIsEditPassword(!isEditPassword) } >
              <FiX className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})} />
            </div>
          </div>
          <motion.div 
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.5 }}
            className={cx('flex flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label onClick={()=> console.log(errors.password) } className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.PASSWORD")}</label>
              { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input 
              className='border rounded-lg px-2 py-1.5 text-sm' 
              placeholder={t("USERS.MODAL.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", { 
                required: isEditPassword ? "required" : false, 
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}  
            />
            <div className='px-3.5' >
              <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
            </div>
          </motion.div>
        </div>
        <div className='flex justify-end mt-6' >
          {
            loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative' > 
              <div className='loader'/> 
            </div>
            :    
            <input 
              type="submit" 
              value={t("COMMON.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light' 
            />
          }
        </div>
      </form>
    </div>
  )
}

function SemiCercleV2(){

  const {t} = useTranslation();
  const userTasksData = [{id:1, balance: 3, finishedAt: 2}, {id:2, balance: 3, finishedAt: 4}, {id:3, balance: 2, finishedAt: 1.5}]

  const totalPercentage = userTasksData.reduce((total, task) => {
    const percent = 100 - ((task.finishedAt * 100) / task.balance);
    return total + Math.round(percent);
  }, 0);
  const absoluteValue = Math.abs(totalPercentage);

  const dataRight = {
    datasets: [{
      data: totalPercentage > 0 ? [absoluteValue, 100 - absoluteValue] : [0, 100],
      backgroundColor: ['rgba(0, 128, 0, 0.6)', 'rgba(0, 0, 0, 0.1)'],
      borderColor: ['rgba(0, 128, 0, 1)', 'rgba(255, 255, 255, 1)'],
      borderWidth: 1,
      circumference: 90,
      rotation: 0 
    }]
  };

  const dataLeft = {
    datasets: [{
      data: totalPercentage < 0 ? [100 - absoluteValue, absoluteValue] : [100,0],
      backgroundColor: ['rgba(0, 0, 0, 0.1)', 'rgba(255, 0, 0, 0.6)'],
      borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 0, 0, 1)'],
      borderWidth: 1,
      circumference: 90,
      rotation: -90 
    }]
  };

  const options = {
    cutout: '80%',
    rotation: 270,
    circumference: 90,
    plugins: {
      legend: {
        display: false
      }
    },
    maintainAspectRatio: false,
  };

  return (
    <div className='grid grid-cols-2 w-[12rem] h-max relative'>
      <div className='w-full h-[6rem]' >
        <Doughnut data={dataLeft} options={options} />
      </div>
      <div className='w-full h-[6rem]' >
        <Doughnut data={dataRight} options={options} />
      </div>
      <div className='absolute bottom-0 left-[50%] translate-x-[-50%]' >
        <h2 className={cx('text-xl font-extrabold text-center', {'text-green-600': totalPercentage > 0, 'text-red-600': totalPercentage < 0})} >
          {totalPercentage > 0 ? `+ ${totalPercentage.toFixed(0)}%` : `${totalPercentage.toFixed(0)}%`}
        </h2>
        <p className='text-[10px] text-gray-400 font-semibold text-center' >{t("USER_DETAILS.DOUG_HNUT_TITLE")}</p>
      </div>
    </div>
  );
}

let tasks = [
  {
    start: new Date(2024, 5, 2),
    end: new Date(2024, 5, 5),
    name: 'Idea',
    id: 'Task 0',
    type:'task',
    progress: 45,
    isDisabled: true,
    styles: { progressColor: '#ffbb54', progressSelectedColor: '#ff9e0d' },
    estimatedTime: 3,
    executionTime: 2
  },
];

export default function UserDetails() {

  const {t} = useTranslation();

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isTableShowen, setIsTableShowen] = useState(true);
  const [dataTest] = useState([
    {id: 1, title: "Total des projets", number: "05", color: "#2a2a66"},
    {id: 2, title: "Total des tâches", number: "30", color: "#"},
    {id: 3, title: "Tâches terminées", number: "05", color: "#23B301"},
    {id: 4, title: "Tâches en cours", number: "05", color: "#7878c7"},
    {id: 5, title: "Tâches retardées", number: "05", color: "#D6323A"},
  ]);

  const openEditModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsEditModalOpen(true);
  }
  const closeEditModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditModalOpen(false);
  }

  const openTaskModal = (task)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
  }
  
  const CustomTaskListHeader = () => {
    return (
      <table style={{ width: '35vw', tableLayout: 'fixed' }} >
        <thead >
          <tr className='w-full text-xs border-y px-1.5 text-gray-400' >
            <th className='h-[49.5px] px-2 text-left ' >{t("PROJECT.TABLE.NAME")}</th>
            <th className='h-[49.5px] px-2 text-left' >{t("PROJECT.TABLE.ESTIMATED_TIME")}</th>
            <th className='h-[49.5px] px-2 text-left' >{t("PROJECT.TABLE.EXECUTION_TIME")}</th>
            <th className='h-[49.5px] px-2 text-left' >{t("PROJECT.TABLE.PROJECT")}</th>
          </tr>
        </thead>
      </table>
    );
  };
  const CustomTaskListTable = ({ tasks }) => {
    return (
      <table style={{ width: '35vw', tableLayout: 'fixed' }}>
        <tbody className='text-xs font-semibold' >
          {tasks.map(task => (
            <tr key={task.id} className='border-b' >
              <td onClick={()=> openTaskModal({}) } className='h-[49.5px] px-2 hover:underline cursor-pointer' >{task.name}</td>
              <td className='h-[49.5px] px-2' >{task.estimatedTime} jours</td>
              <td className='h-[49.5px] px-2' >{task.executionTime} jours</td>
              <td className='h-[49.5px] px-2' >Project name</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div className='mt-6' >
      <div className='mb-4 flex items-center justify-between' >
        <div className='flex items-center gap-4' >
          <div className='p-2 border rounded-full' >
            <FiUser size={38} />
          </div>
          <div >
            <h2 className='font-bold text-xl mb-1' >User Name</h2>
            <h4 className='font-semibold text-sm text-gray-500' >Front-end Developer</h4>
          </div>
        </div>
        <div className='border px-2 py-2 rounded-lg h-max flex justify-center' >
          <SemiCercleV2/>
        </div>
      </div>
      <div className='border p-4 rounded-lg grid grid-cols-5 mb-5' >
        {
          dataTest.map((it)=>
            <div key={it.id} >
              <p className='text-sm text-gray-400 font-semibold mb-1' >{it.title}</p>
              <div className='text-2xl font-semibold border-l-2 pl-2' style={{borderColor: it.color}} >
                {it.number}
              </div>
            </div>
          )
        }
      </div>
      <div className='grid grid-cols-3 gap-4' >
        <div className='border px-0 pb-2 pt-5 rounded-lg flex flex-col gap-2 col-span-3 relative' >
          <div className='flex items-center justify-between text-gray-500 px-3 absolute top-[-12px] left-3 bg-white' >
            <p className='font-semibold text-sm' >{t("USER_DETAILS.INFORMATIONS_TITLE")}</p>
          </div>
          <button onClick={()=> setIsTableShowen(!isTableShowen) } className='flex items-center hover:border-purple-light justify-between border rounded-full text-gray-500 px-3 absolute top-[-12px] right-3 bg-white' >
            <p className='font-semibold text-sm flex items-center gap-2' >
              <FiTable/>
              { isTableShowen ? t("USER_DETAILS.HIDE_TABLE_BTN") : t("USER_DETAILS.SHOW_TABLE_BTN") }
            </p>
          </button>
          {/* <div className="relative overflow-x-auto h-full">
            <table className="w-full text-sm text-left text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.NAME")}
                  </th>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.STATUS")}
                  </th>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.PROJECT")}
                  </th>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.PRIORITY")}
                  </th>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.ESTIMATED_TIME")}
                  </th>
                  <th scope="col" className="p-3">
                    {t("PROJECT.TABLE.EXECUTION_TIME")}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="bg-white customBorderBottom">
                  <th scope="row" className="p-3 font-medium text-gray-900 whitespace-nowrap">
                    <button onClick={()=> openTaskModal({}) } className='hover:underline' >
                      Tache test
                    </button>
                  </th>
                  <td className="p-3">
                    <div className={cx(styles.status, {[styles.progress]: "En cours" === "En cours", [styles.finish]: "En cours" === "Terminé"})} >
                      En cours
                    </div>
                  </td>
                  <td className="p-3">
                    test
                  </td>
                  <td className="p-3">
                    <div className={cx((styles.priority), {[styles.medium]: "Medium" === "Medium", [styles.hight]: "Medium" === "Hight"})} >
                      Medium
                    </div>
                  </td>
                  <td className="p-3">
                    test
                  </td>
                  <td className="p-3">
                    test
                  </td>
                </tr>
              </tbody>
            </table>
          </div> */}
          <Gantt 
            locale="fr"
            tasks={tasks} 
            viewMode={ViewMode.Day}
            TaskListHeader={CustomTaskListHeader}
            TaskListTable={(props) => <CustomTaskListTable tasks={props.tasks} />}
            fontSize='12px'
            listCellWidth={isTableShowen ? "155px" : ""}
            onClick={()=> openTaskModal({}) }
          />
        </div>
      </div>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal} >
            <TaskModal close={closeTaskModal} task={taskData} />
          </SideModal>
        }
      </AnimatePresence>
      <Modal isOpen={isEditModalOpen} close={closeEditModal} title={t("ACCOUNT.INFORMATIONS_TITLE")} >
        <EditModal close={closeEditModal} />
      </Modal>
    </div>
  )
}
