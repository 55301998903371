import React, { useState, useEffect, useContext, useRef } from "react";
import cx from "classnames";
import { Outlet, Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  FiGrid,
  FiUsers,
  FiSettings,
  FiBriefcase,
  FiUser,
  FiSearch,
  FiBarChart2,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { TfiUnlock, TfiLock } from "react-icons/tfi";
import { FaTasks } from "react-icons/fa";
import { IoLanguage } from "react-icons/io5";
import { AnimatePresence, motion } from "framer-motion";
import { Row_Between, Row, Grid_2 } from "../Styles/DefaultComponents";
import styles from "../Styles/root.module.scss";
import { UserContext } from "../Contexts/UserContext";
import Modal from "./Modal";
import MyPerformance from "./MyPerformance";
import TaskChat from "./TaskChat";
import { loginEvent } from "../Contexts/events";

import { SlOrganization } from "react-icons/sl";

function NormalSideBar({
  isWideSideBar,
  isFixedWideSideBar,
  setSelectedNav,
  toggleSideBar,
  selectedNav,
  toggleMenuType,
}) {
  const { t } = useTranslation();

  const theme = "dark";
  const [animateText, setAnimateText] = useState(false);

  // Trigger animation when component mounts
  useEffect(() => {
    if (isWideSideBar) {
      setTimeout(() => setAnimateText(true), 10); // Delay animation start
    } else {
      setAnimateText(false);
    }
  }, [isWideSideBar]);

  return (
    <div
      className={cx(styles.sideBar, { [styles.sideBarOpen]: isWideSideBar })}
    >
      <div>
        {isWideSideBar ? (
          <Row_Between
            style={{
              marginBottom: "22px",
              justifyContent: !isWideSideBar && "center",
              width: !isWideSideBar && "36px",
            }}
          >
            <div className="flex items-center gap-2">
              <div className="bg-white rounded-full w-10 h-10 flex items-center justify-center">
                <svg
                  width="26"
                  height="26"
                  fill="currentColor"
                  className="text-[#2a2a66]"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                  ></path>
                  <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                  <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                </svg>
              </div>
              <span
                style={{
                  color: "#edecf9",
                  fontWeight: 600,
                  fontSize: animateText ? "16px" : "0px", // Animating font-size
                  maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                  opacity: animateText ? 1 : 0, // Smooth fade effect
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  transition:
                    "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                }}
                onClick={() => console.log(isWideSideBar)}
              >
                Tasks app
              </span>
            </div>
            <div
              className={styles["openSideBarIcon-btn"]}
              onClick={() => toggleSideBar()}
            >
              {isFixedWideSideBar ? <TfiLock /> : <TfiUnlock />}
            </div>
          </Row_Between>
        ) : (
          <div className="flex items-center flex-col gap-2 mb-5">
            <div
              onClick={() => toggleSideBar()}
              className="bg-white rounded-full w-9 h-9 flex items-center justify-center cursor-pointer"
            >
              <svg
                width="22"
                height="22"
                fill="currentColor"
                className="text-[#2a2a66]"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M10.854 7.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                ></path>
                <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
              </svg>
            </div>
            {/* <div className={styles['openSideBarIcon-btn']} onClick={()=> toggleSideBar() } >
              <FiMoreVertical className={isWideSideBar ? styles['openSideBarIcon-V'] : styles['openSideBarIcon-H']} />
            </div> */}
          </div>
        )}
        <ul
          className={styles.nav}
          style={{ width: !isWideSideBar && "max-content" }}
        >
          <li onClick={() => setSelectedNav("dashboard")} title="dashboard">
            <Link
              to="/dashboard"
              className={cx([styles["nav-btn"], styles["nav-btn-dark"]], {
                [styles["selected-nav-dark"]]: selectedNav === "dashboard",
              })}
            >
              <FiBarChart2 />
              {isWideSideBar && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: animateText ? "12px" : "0px", // Animating font-size
                    maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                    opacity: animateText ? 1 : 0, // Smooth fade effect
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflowing text
                    transition:
                      "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                  }}
                  className="whitespace-nowrap"
                >
                  {t("ROOT.SIDE_BAR.TEAM_PROGRESS")}
                </span>
              )}
            </Link>
          </li>
          <li>
            <div className={styles.navSubLine} />
          </li>
          <li onClick={() => setSelectedNav("overview")} title="Overview">
            <Link
              className={cx([styles["nav-btn"], styles["nav-btn-dark"]], {
                [styles["selected-nav-dark"]]: selectedNav === "overview",
              })}
              to="/"
            >
              <FiGrid size={18} />
              {isWideSideBar && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: animateText ? "12px" : "0px", // Animating font-size
                    maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                    opacity: animateText ? 1 : 0, // Smooth fade effect
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflowing text
                    transition:
                      "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                  }}
                >
                  {t("ROOT.SIDE_BAR.ALL_PROJECTS")}
                </span>
              )}
            </Link>
          </li>
          <li onClick={() => setSelectedNav("tasks")} title={t("COMMON.TASKS")}>
            <Link
              className={cx([styles["nav-btn"], styles["nav-btn-dark"]], {
                [styles["selected-nav-dark"]]: selectedNav === "tasks",
              })}
              to="/tasks"
            >
              <FaTasks size={18} />
              {isWideSideBar && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: animateText ? "12px" : "0px", // Animating font-size
                    maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                    opacity: animateText ? 1 : 0, // Smooth fade effect
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflowing text
                    transition:
                      "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                  }}
                >
                  {t("COMMON.TASKS")}
                </span>
              )}
            </Link>
          </li>
        </ul>
      </div>
      <ul
        className={styles.nav}
        style={{ width: !isWideSideBar && "max-content" }}
      >
        <li title={t("ROOT.SIDE_BAR.SETTINGS")}>
          <div
            onClick={() => toggleMenuType()}
            className={cx([styles["nav-btn"], styles["nav-btn-dark"]])}
          >
            <FiSettings />
            {isWideSideBar && (
              <span
                style={{
                  fontWeight: 600,
                  fontSize: animateText ? "12px" : "0px", // Animating font-size
                  maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                  opacity: animateText ? 1 : 0, // Smooth fade effect
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  transition:
                    "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                }}
                className="whitespace-nowrap"
              >
                {t("ROOT.SIDE_BAR.SETTINGS")}
              </span>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

function SettingsSideBar({
  isWideSideBar,
  isFixedWideSideBar,
  setSelectedNav,
  toggleSideBar,
  selectedNav,
  toggleMenuType,
}) {
  const { t } = useTranslation();

  const theme = "light";
  const [animateText, setAnimateText] = useState(false);

  // Trigger animation when component mounts
  useEffect(() => {
    if (isWideSideBar) {
      setTimeout(() => setAnimateText(true), 10); // Delay animation start
    } else {
      setAnimateText(false);
    }
  }, [isWideSideBar]);

  return (
    <div
      className={cx(styles.settingsSideBar, {
        [styles.sideBarOpen]: isWideSideBar,
      })}
    >
      <div>
        {isWideSideBar ? (
          <Row_Between
            style={{
              marginBottom: "22px",
              justifyContent: !isWideSideBar && "center",
              width: !isWideSideBar && "36px",
            }}
          >
            <div className="flex items-center gap-2">
              <div className="bg-white rounded-full w-10 h-10 flex items-center justify-center">
                <FiSettings size={26} color="#2a2a66" />
              </div>
              <span
                style={{
                  color: "#2a2a66",
                  fontWeight: 600,
                  fontSize: animateText ? "16px" : "0px", // Animating font-size
                  maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                  opacity: animateText ? 1 : 0, // Smooth fade effect
                  whiteSpace: "nowrap", // Prevent text wrapping
                  overflow: "hidden", // Hide overflowing text
                  transition:
                    "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                }}
              >
                Tasks app
              </span>
            </div>
            <div
              className={styles["openSideBarIcon-btn"]}
              onClick={() => toggleSideBar()}
            >
              {isFixedWideSideBar ? <TfiLock /> : <TfiUnlock />}
            </div>
          </Row_Between>
        ) : (
          <div className="flex items-center flex-col gap-2 mb-5">
            <div
              onClick={() => toggleSideBar()}
              className="bg-white rounded-full w-9 h-9 flex items-center justify-center cursor-pointer"
            >
              <FiSettings size={22} color="#2a2a66" />
            </div>
            {/* <div className={styles['openSideBarIcon-btn']} onClick={()=> toggleSideBar() } >
              <FiMoreVertical className={isWideSideBar ? styles['openSideBarIcon-V'] : styles['openSideBarIcon-H']} />
            </div> */}
          </div>
        )}
        <ul
          className={styles.nav}
          style={{ width: !isWideSideBar && "max-content" }}
        >
          <li onClick={() => setSelectedNav("users")} title="users">
            <Link
              to="/users"
              className={cx([styles["nav-btn"], styles["nav-btn-light"]], {
                [styles["selected-nav-light"]]: selectedNav === "users",
              })}
            >
              <FiUsers />
              {isWideSideBar && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: animateText ? "12px" : "0px", // Animating font-size
                    maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                    opacity: animateText ? 1 : 0, // Smooth fade effect
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflowing text
                    transition:
                      "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                  }}
                  className="whitespace-nowrap"
                >
                  {t("ROOT.SIDE_BAR.USERS")}
                </span>
              )}
            </Link>
          </li>
          <li onClick={() => setSelectedNav("teams")} title="teams">
            <Link
              to="/teams"
              className={cx([styles["nav-btn"], styles["nav-btn-light"]], {
                [styles["selected-nav-light"]]: selectedNav === "teams",
              })}
            >
              <SlOrganization />
              {isWideSideBar && (
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: animateText ? "12px" : "0px", // Animating font-size
                    maxWidth: animateText ? "200px" : "0px", // Prevent content shifting
                    opacity: animateText ? 1 : 0, // Smooth fade effect
                    whiteSpace: "nowrap", // Prevent text wrapping
                    overflow: "hidden", // Hide overflowing text
                    transition:
                      "font-size 0.5s ease, opacity 0.5s ease, max-width 0.5s ease",
                  }}
                  className="whitespace-nowrap"
                >
                  {t("ROOT.SIDE_BAR.TEAMS")}
                </span>
              )}
            </Link>
          </li>
        </ul>
      </div>
      <ul
        className={styles.nav}
        style={{ width: !isWideSideBar && "max-content" }}
      >
        <li title="dashboard">
          <div
            onClick={() => toggleMenuType()}
            className={cx([styles["nav-btn"], styles["nav-btn-light"]])}
          >
            <FiGrid />
            {isWideSideBar && (
              <span className="whitespace-nowrap">{t("COMMON.TASKS")}</span>
            )}
          </div>
        </li>
      </ul>
    </div>
  );
}

export default function Root() {
  let location = useLocation();
  const { t , i18n } = useTranslation();
  const {
    setWideSideBarValue,
    theme,
    isTaskChatOpen,
    taskChatData,
    closeTaskChat,
    user,
  } = useContext(UserContext);

  const settingsMenuRef = useRef(null);
  const accountMenuRef = useRef(null);
  const LanguagaeMenuRef = useRef(null);

  const [isWideSideBar, setIsWideSideBar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isDashboardsMenuOpen, setIsDashboardsMenuOpen] = useState(false);
  const [selectedNav, setSelectedNav] = useState("home");
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState(false);
  const [isRightBarOpen, setIsRightBarOpen] = useState(false);
  const [menuType, setMenuType] = useState("normal");

  const logOut = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    loginEvent.emit("login", false);
  };

  const handleMouseEnter = () => {
    if (!isWideSideBar) {
      setTimeout(() => setIsHovered(true), 10);
    }
  };

  const handleMouseLeave = () => {
    if (!isWideSideBar) {
      setTimeout(() => setIsHovered(false), 10);
    }
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
    setIsSettingsMenuOpen(false);
    setIsLanguageMenuOpen(false);

  };
  const toggleLanguageMenu = () => {
    setIsLanguageMenuOpen(!isLanguageMenuOpen);
    setIsAccountMenuOpen(false);
    setIsSettingsMenuOpen(false);
  };
  const toggleSettingsMenu = () => {
    setIsSettingsMenuOpen(!isSettingsMenuOpen);
    setIsAccountMenuOpen(false); 
  };

  const toggleMenuType = () => {
    if (menuType === "normal") {
      setMenuType("settings");
    } else {
      setMenuType("normal");
    }
  };

  const toggleSideBar = () => {
    if (isWideSideBar && isDashboardsMenuOpen) {
      setIsDashboardsMenuOpen(false);
    }
    setIsWideSideBar(!isWideSideBar);
  };

  useEffect(() => {
    switch (location.pathname) {
      case "/":
        setSelectedNav("overview");
        break;
      case "/dashboard":
        setSelectedNav("dashboard");
        break;
      case "/my-tasks":
        setSelectedNav("my-tasks");
        break;
      case "/team":
        setSelectedNav("team");
        break;
      case "/users":
        setSelectedNav("users");
        break;
      case "/teams":
        setSelectedNav("teams");
        break;
      case "/tasks":
        setSelectedNav("tasks");
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    // if (isWideSideBar) {
    //   document.documentElement.style.setProperty('--side-bar-width', '15%');
    //   document.documentElement.style.setProperty('--content-width', '85%');
    // } else {
    //   document.documentElement.style.setProperty('--side-bar-width', '5%');
    //   document.documentElement.style.setProperty('--content-width', '95%');
    // }
    if (isWideSideBar || isHovered) {
      // document.documentElement.style.setProperty('--side-bar-width', '15%');
      document.documentElement.style.setProperty(
        "--side-bar-width",
        "max-content"
      );
      isRightBarOpen
        ? document.documentElement.style.setProperty("--content-width", "67%")
        : document.documentElement.style.setProperty("--content-width", "85%");
    } else {
      document.documentElement.style.setProperty(
        "--side-bar-width",
        "max-content"
      );
      isRightBarOpen
        ? document.documentElement.style.setProperty("--content-width", "77%")
        : document.documentElement.style.setProperty("--content-width", "95%");
    }
    setTimeout(() => {
      setWideSideBarValue(isWideSideBar);
    }, 500);
  }, [isWideSideBar, isRightBarOpen, isHovered]);

  const handleClickOutside = (event) => {
    if (
      accountMenuRef.current &&
      !accountMenuRef.current.contains(event.target)
    ) {
      setIsAccountMenuOpen(false); 
    }
    if (
      settingsMenuRef.current &&
      !settingsMenuRef.current.contains(event.target)
    ) {
      setIsSettingsMenuOpen(false);
    }
    if (
      LanguagaeMenuRef.current &&
      !LanguagaeMenuRef.current.contains(event.target)
    ) {
      setIsLanguageMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      className={cx(styles.container, styles["no-select"], {
        [styles["container-light"]]: theme === "light",
        [styles["container-dark"]]: theme === "dark",
      })}
    >
      <AnimatePresence mode="wait">
        {menuType === "normal" ? (
          <motion.div
            key="normal"
            initial={{ x: -100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 100, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={cx(styles.sideBarContainer, {
              [styles.sideBarContainerOpen]: isWideSideBar || isHovered,
            })}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <NormalSideBar
              isWideSideBar={isWideSideBar || isHovered}
              isFixedWideSideBar={isWideSideBar}
              setSelectedNav={setSelectedNav}
              toggleSideBar={toggleSideBar}
              selectedNav={selectedNav}
              theme={theme}
              toggleMenuType={toggleMenuType}
            />
          </motion.div>
        ) : (
          <motion.div
            key="settings"
            initial={{ x: 100, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -100, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={cx(styles.sideBarContainer, {
              [styles.sideBarContainerOpen]: isWideSideBar || isHovered,
            })}
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
          >
            <SettingsSideBar
              isWideSideBar={isWideSideBar || isHovered}
              isFixedWideSideBar={isWideSideBar}
              setSelectedNav={setSelectedNav}
              toggleSideBar={toggleSideBar}
              selectedNav={selectedNav}
              theme={theme}
              toggleMenuType={toggleMenuType}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <div
        className={cx(styles.content, {
          [styles["content-light"]]: theme === "light",
          [styles["content-dark"]]: theme === "dark",
        })}
      >
        <div className={styles.globalHeader}>
          <p>
            {t("COMMON.WELCOME")} {user.firstName} {user.lastName},
          </p>
          <Row style={{ gap: "8px", position: "relative" }}>
            {/*<button className={styles.iconWrapper} >*/}
            {/*  <FiSearch size={14}/>*/}
            {/*</button>*/}
            <button
              className={styles.iconWrapper}
              onClick={() => toggleLanguageMenu()}
            >
              <IoLanguage size={14} />
            </button>
            {isLanguageMenuOpen && (
              <div className={styles.floatSettingsMenu} ref={LanguagaeMenuRef}>
                <ul>
                  <li>
                    <Link
                      onClick={() => {
                        i18n.changeLanguage("fr");
                        setIsLanguageMenuOpen(false);
                      }}
                    >
                      <div className="py-1.5 px-3">{t('COMMON.FRENCH')}</div>
                    </Link>
                  </li> 
                  <li>
                    <Link
                      onClick={() => {
                        i18n.changeLanguage("en");
                        setIsLanguageMenuOpen(false);
                      }}
                    >
                      <div className="py-1.5 px-3">{t('COMMON.ENGLISH')}</div>
                    </Link>
                  </li>
                </ul>
              </div>
            )}

            <>
              <button
                className={styles.iconWrapper}
                onClick={() => toggleAccountMenu()}
              >
                <FiUser size={14} />
              </button>
              {isAccountMenuOpen && (
                <div className={styles.floatSettingsMenu} ref={accountMenuRef}>
                  <ul>
                    <li>
                      <Link
                        to="/account"
                        onClick={() => setIsAccountMenuOpen(false)}
                      >
                        <div className="py-1.5 px-3">
                          {t("ROOT.TOP_BAR.ACCOUNT")}
                        </div>
                      </Link>
                    </li>
                    <li onClick={() => logOut()} className="py-1.5 px-3">
                      {t("ROOT.TOP_BAR.LOG_OUT")}
                    </li>
                  </ul>
                </div>
              )}
            </>
            {/* <button className={styles.iconWrapper} onClick={()=> toggleMenuType() } >
              {
                menuType === "normal"
                ? <FiSettings size={14} />
                : <FiGrid size={14} />
              }
            </button> */}
            {/* <button className={styles.iconWrapper} onClick={()=> toggleSettingsMenu() } >
              <FiSettings size={14} />
            </button> */}
            {/* {
            isSettingsMenuOpen &&
              <div className={styles.floatSettingsMenu} ref={settingsMenuRef} >
                <ul>
                  <li onClick={()=> setProject("") } >Change project</li>
                  <li>{t('ROOT.TOP_BAR.SETTINGS')}</li>
                </ul>
              </div>
            } */}
          </Row>
        </div>
        <Outlet />
      </div>
      {isRightBarOpen && (
        <MyPerformance setIsRightBarOpen={setIsRightBarOpen} />
      )}
      {!isRightBarOpen && (
        <div
          onClick={() => setIsRightBarOpen(true)}
          className="absolute top-[50%] right-0 translate-x-[36%] bg-white border-t border-x rounded-t-lg cursor-pointer py-2 px-3 rotate-[-90deg] text-sm font-semibold text-gray-500"
        >
          {t("ROOT.RIGHT_BAR.TITLE")}
        </div>
      )}
      <AnimatePresence>
        {isTaskChatOpen && (
          <TaskChat close={closeTaskChat} task={taskChatData} />
        )}
      </AnimatePresence>
    </div>
  );
}
