import React, {useState, useRef, useEffect, useContext} from 'react';
import { useTranslation } from 'react-i18next';
import { FiBarChart2, FiCheckCircle, FiChevronDown, FiChevronUp, FiEdit2, FiEdit3, FiSearch, FiUser, FiX, FiXOctagon } from 'react-icons/fi';
import { useForm } from "react-hook-form";
import { motion } from 'framer-motion';
import cx from 'classnames';
import { useNavigate } from 'react-router-dom';
import { IoMdInformationCircleOutline } from "react-icons/io";
import Modal from './Modal';
import {UserContext} from "../Contexts/UserContext";
import {createUser, getAllRoles, getAllTenantRoles, getUserById, updateUser} from "../services/usersService";
import {getAllTenantsPage, showTenant} from "../services/tenantService";
import {createTenantUser, getUserByTenantByPage, updateTenantUser} from "../services/tenantUsersService";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function NewUserModal({user}){

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t } = useTranslation();
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const [roleList, setRoleList] = useState([{id: 1, name: "Administrateur", value: "ADMIN"}, {id: 2, name: "Responsable", value: "RESPO"}, {id: 3, name: "Utilisateur", value: "USER"}])
  const [role, setRole] = useState("");
  const [isUserSubmit, setIsUserSubmit] = useState(false);

  const selectRole = (val)=>{
    setRole(val);
    setIsRoleMenuOpen(false);
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onSubmit = (data)=>{
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: data.password,
      // roles: selectedRoles,
      fonction: data.fonction
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    setUserCreated(true)
    setLoading(false);

  }

  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("USERS.MODAL.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[35vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
        <div className='flex items-center justify-between gap-3 mb-3'>
          <div className='flex flex-col w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("USERS.MODAL.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("USERS.MODAL.FIRST_NAME")} *</label>
              {errors.firstName && <label
                className='text-xs text-red-500 font-semibold truncate max-w-[40%]'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
        </div>
        {/*<div className='flex flex-col mb-3 relative' >*/}
        {/*  <div className='px-2.5 mb-0.5 flex items-center justify-between' >*/}
        {/*    <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.EMAIL")} *</label>*/}
        {/*    { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}*/}
        {/*  </div>*/}
        {/*  <input */}
        {/*    className='border rounded-lg px-2 py-1.5 text-sm' */}
        {/*    placeholder={t("USERS.MODAL.EMAIL")}*/}
        {/*    {...register("email", { required: true, pattern: /^\S+@\S+$/i })} */}
        {/*    onChange={(e)=> validateEmail(e.target.value) }*/}
        {/*  />*/}
        {/*  {*/}
        {/*    isEmailValide */}
        {/*    ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]' />*/}
        {/*    : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]' />*/}
        {/*  }*/}
        {/*</div>*/}
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col w-max'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm w-max'>
              <label className="text-gray-500" onClick={()=> console.log(user) } >
                {user?.username.split('_')[0] + "_"}
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("COMMON.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.EMAIL")} *</label>
              {errors.email ?
                errors.email.type === "required"
                  ? <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>
                  : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("USERS.MODAL.PASSWORD")} *</label>
            {errors.password &&
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("USERS.MODAL.PASSWORD")}
            // {...register("password", { required: true })}
            {...register("password", {
              required: "required",
              minLength: {
                value: 8,
                message: "Le mot de passe doit contenir au moins 8 caractères"
              },
              maxLength: {
                value: 16,
                message: "Le mot de passe ne doit pas dépasser 16 caractères"
              },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
              }
            })}
          />
          <div className='px-3.5'>
            <p
              className='text-xs text-gray-500'>{errors.password ? errors.password.message !== "required" && errors.password.message : null}</p>
          </div>
        </div>
        <div className='relative w-full mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("USERS.MODAL.ROLE")} *</label>
            {(isUserSubmit && Object.keys(role).length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label> : null}
          </div>
          <button type='button' onClick={() => setIsRoleMenuOpen(!isRoleMenuOpen)}
                  className={cx('border flex items-center justify-between rounded-lg px-2 py-1.5 text-sm w-full')}>
            {
              Object.keys(role).length > 0
                ? <span>{role.name}</span>
                : <span className='text-gray-500'>{t("USERS.MODAL.ROLE")} *</span>
            }
            {
              isRoleMenuOpen
                ? <FiChevronUp/>
                : <FiChevronDown/>
            }
          </button>
          {
            isRoleMenuOpen &&
            <ul className='bg-white p-1 border absolute top-13 w-full z-20 rounded-lg'>
              {
                roleList.map((it) =>
                  <li key={it.id} onClick={() => selectRole(it)}
                      className={cx('p-2 rounded-lg text-sm cursor-pointer hover:bg-gray-100', {'bg-purple-light text-white hover:text-black': role.id === it.id})}>{it.name}</li>
                )
              }
            </ul>
          }
        </div>
        <div className='flex flex-col w-full mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("USERS.MODAL.FONCTION")} *</label>
            {errors.fonction &&
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("USERS.MODAL.FONCTION")}
            {...register("fonction", {required: true})}
          />
        </div>
        <div className='mt-4 flex items-center gap-2 p-2 bg-blue-50 text-blue-600 rounded-lg border border-blue-200'>
          <IoMdInformationCircleOutline  className="w-5 h-5 text-blue-500 font-bold" />
          <p className='text-xs'>
            Les champs avec un <span className='text-red-500'>*</span> à côté sont obligatoires.
          </p>
        </div>
        <div className='flex justify-between mt-6'>

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled}
                   onChange={() => setEnabled(!enabled)}/>
            <div
              className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span
              className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("USERS.MODAL.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative'>
                <div className='loader'/>
              </div>
              :
              <input
                type="submit"
                onClick={() => setIsUserSubmit(true)}
                value={t("COMMON.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light'
              />
          }
        </div>
      </form>
    </div>
  )
}

function AddUserModal({user}){

  const rolesInputRef = useRef(null);
  const clientsInputRef = useRef(null);
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { t, i18n } = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isClientListOpen, setIsClientListOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [selectedClientInfo, setSelectedClientInfo] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientSearchInput, setClientSearchInput] = useState("");

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userCreated, setUserCreated] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item)=>{
    if(selectedRoles.includes(item.id)){
      setSelectedRoles(selectedRoles.filter(it=> it !== item.id ));
      setSelectedRolesObj(selectedRolesObj.filter(it=> it !== item ));
    }else{
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const selectClient = (item)=>{
    setSelectedClient(item);
    setIsClientListOpen(false);
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userData = {
      email: data.email,
      username: data.userName,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: data.password,
      roles: selectedRoles,
      tenant: user.tenantId
    }

    formData.append('userDto', new Blob([JSON.stringify(userData)], { type: 'application/json' }));

    createTenantUser(formData)
      .then((response)=> setUserCreated(true) )
      .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
      .finally(()=> setLoading(false) );

  }

  useEffect(() => {
    if(Object.keys(selectedClient).length > 0){
      showTenant(selectedClient.id)
        .then((data)=>{ setSelectedClientInfo(data); })
        .catch((err)=>{ console.log(err)})
    }
  }, [selectedClient]);

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllTenantRoles()
      .then((data)=> setAllRoles(data) )
      .catch((err)=> console.log(err) )
  },[])

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
    if (clientsInputRef.current && !clientsInputRef.current.contains(event.target)) {
      setIsClientListOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userCreated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("USERS.MODAL.USER_CREATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[35vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
        <div className={cx('grid grid-cols-2 gap-3 mb-3')}>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.FIRST_NAME")} *</label>
              {errors.firstName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col col-span-1'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm'>
              <label className="text-gray-500" >
                {user?.username.split('_')[0] + "_"}
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("COMMON.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.EMAIL")} *</label>
              {errors.email ?
                errors.email.type === "required"
                  ? <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>
                  : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.PASSWORD")} *</label>
            {errors.password &&
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("COMMON.PASSWORD")}
            // {...register("password", { required: true })}
            {...register("password", {
              required: "required",
              minLength: {
                value: 8,
                message: t("ERRORS.PASSWORD_MIN_LENGTH")
              },
              maxLength: {
                value: 16,
                message: t("ERRORS.PASSWORD_MIN_LENGTH")
              },
              pattern: {
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                message: t("ERRORS.PASSWORD_PATTERN")
              }
            })}
          />
          <div className='px-3.5'>
            <p
              className='text-xs text-gray-500'>{errors.password ? errors.password.message !== "required" && errors.password.message : null}</p>
          </div>
        </div>
        <div className='flex flex-col mb-3 relative'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.ROLE")} *</label>
            {(isUserSubmit && selectedRoles.length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef}>
            <div onClick={() => setIsRolesPickerOpen(!isRolesPickerOpen)}
                 className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                      ? <span className='text-gray-500'>{t("COMMON.ROLE")} *</span>
                      : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                  ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                 onClick={() => setIsRolesPickerOpen(false)}/>
                  : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                   onClick={() => setIsRolesPickerOpen(true)}/>
              }
            </div>
            {
              isRolesPickerOpen &&
              <div
                className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("COMMON.SEARCH")}
                      value={roleInput}
                      onChange={(e) => onTypeRole(e.target.value)}
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it) =>
                      {
                        if(it.id === 1){
                          return (
                            selectedRoles.some(id => id !== 1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-purple-light text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        } else {
                          return (
                            selectedRoles.includes(1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-purple-light text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        }
                      }
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='mt-4 flex items-center gap-2 p-2 bg-blue-50 text-blue-600 rounded-lg border border-blue-200'>
          <IoMdInformationCircleOutline  className="w-5 h-5 text-blue-500 font-bold" />
          <p className='text-xs'>
            Les champs avec un <span className='text-red-500'>*</span> à côté sont obligatoires.
          </p>
        </div>
        <div className='flex justify-between mt-6'>

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled}
                   onChange={() => setEnabled(!enabled)}/>
            <div
              className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span
              className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("USERS.MODAL.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative flex items-center justify-center'>
                <div className='loader'/>
              </div>
              :
              <input
                onClick={() => setIsUserSubmit(true)}
                type="submit"
                value={t("COMMON.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light'
              />
          }
        </div>
      </form>
    </div>
  )
}

function UpdateUserModalV0({userData}) {

  const rolesInputRef = useRef(null);
  const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm();
  const {t, i18n} = useTranslation();
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userUpdated, setUserUpdated] = useState(false);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isRoleMenuOpen, setIsRoleMenuOpen] = useState(false);
  const [roleList, setRoleList] = useState([{id: 1, name: "Administrateur", value: "ADMIN"}, {
    id: 2,
    name: "Responsable",
    value: "RESPO"
  }, {id: 3, name: "Utilisateur", value: "USER"}]);
  const [role, setRole] = useState("");
  const [isStatusListOpen, setIsStatusListOpen] = useState(false);
  const [statusList, setStatusList] = useState([{id: 1, name: "Disponible", value: "DISPO"}, {
    id: 2,
    name: "En congé",
    value: "CONGE"
  }]);
  const [status, setStatus] = useState("");

  const selectRole = (val) => {
    setRole(val);
    setIsRoleMenuOpen(false);
  }

  const selectStatus = (val) => {
    setStatus(val);
    setIsStatusListOpen(false);
  }

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const onSubmit = (data) => {
    setLoading(true);
    const formData = new FormData();

    const userNewData = {
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: isEditPassword ? data.password : null,
      fonction: data.fonction
    }

    formData.append('userDto', new Blob([JSON.stringify(userNewData)], {type: 'application/json'}));
    setLoading(false);
  }

  useEffect(() => {
    if (userData && Object.keys(userData).length > 0) {
      setValue('lastName', userData.lastName);
      setValue('firstName', userData.firstName);
      setValue('email', userData.email);
      setEnabled(userData.enabled);
      validateEmail(userData.email);
    }
  },[userData]);

  if(userUpdated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("HOME.USERS_VIEW.USER_UPDATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[38vw]' >
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full' >
        <div className='flex items-center justify-between gap-3 mb-3' >
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.LAST_NAME")} *</label>
              { errors.lastName && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.LAST_NAME")}
              {...register("lastName", { required: true })}
            />
          </div>
          <div className='flex flex-col w-full' >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.FIRST_NAME")} *</label>
              { errors.firstName && <label className='text-xs text-red-700 font-semibold truncate max-w-[40%]' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.FIRST_NAME")}
              {...register("firstName", { required: true })}
            />
          </div>
        </div>
        <div className='flex flex-col mb-3 relative' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.EMAIL")} *</label>
            { errors.email && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 pr-10 py-1.5 text-sm'
            placeholder={t("USERS.MODAL.EMAIL")}
            {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
            onChange={(e)=> validateEmail(e.target.value) }
          />
          {
            isEmailValide
            ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]' />
            : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]' />
          }
        </div>
        <div className='relative w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.ROLE")} *</label>
            { (isUserSubmit && Object.keys(role).length === 0 ) ? <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label> : null}
          </div>
          <button type='button' onClick={()=> setIsRoleMenuOpen(!isRoleMenuOpen) } className={cx('border flex items-center justify-between rounded-lg px-2 py-1.5 text-sm w-full')}  >
            {
              Object.keys(role).length > 0
              ? <span >{role.name}</span>
              : <span className='text-gray-500' >{t("USERS.MODAL.ROLE")} *</span>
            }
            { isRoleMenuOpen ? <FiChevronUp/> : <FiChevronDown/> }
          </button>
          {
            isRoleMenuOpen &&
            <ul className='bg-white p-1 border absolute top-13 w-full z-20 rounded-lg' >
              { roleList.map((it)=>
                  <li key={it.id} onClick={()=> selectRole(it) } className={cx('p-2 rounded-lg text-sm cursor-pointer hover:bg-gray-100',{'bg-purple-light text-white hover:text-black': role.id === it.id})} >{it.name}</li>
                )
              }
            </ul>
          }
        </div>
        <div className='flex flex-col w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.FONCTION")} *</label>
            { errors.fonction && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
          </div>
          <input
            className='border rounded-lg px-2 py-1.5 text-sm'
            placeholder={t("USERS.MODAL.FONCTION")}
            {...register("fonction", { required: true })}
          />
        </div>
        <div className='relative w-full mb-3' >
          <div className='px-2.5 mb-0.5 flex items-center justify-between' >
            <label className='text-xs text-gray-500 font-semibold' >{t("COMMON.STATUS")} *</label>
            { (isUserSubmit && Object.keys(status).length === 0 ) ? <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label> : null}
          </div>
          <button type='button' onClick={()=> setIsStatusListOpen(!isStatusListOpen) } className={cx('border flex items-center justify-between rounded-lg px-2 py-1.5 text-sm w-full')}  >
            {
              Object.keys(status).length > 0
              ? <span >{status.name}</span>
              : <span className='text-gray-500' >{t("COMMON.STATUS")} *</span>
            }
            { isStatusListOpen ? <FiChevronUp/> : <FiChevronDown/> }
          </button>
          {
            isStatusListOpen &&
            <ul className='bg-white p-1 border absolute top-13 w-full z-20 rounded-lg' >
              { statusList.map((it)=>
                  <li key={it.id} onClick={()=> selectStatus(it) } className={cx('p-2 rounded-lg text-sm cursor-pointer hover:bg-gray-100',{'bg-purple-light text-white hover:text-black': status.id === it.id})} >{it.name}</li>
                )
              }
            </ul>
          }
        </div>
        <div className='border p-2 rounded-lg' >
          <div className='flex items-center justify-between' >
            <p className='text-gray-500 text-sm font-semibold' >{t("USERS.MODAL.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={()=> setIsEditPassword(!isEditPassword) } >
              <FiX className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})} />
            </div>
          </div>
          <motion.div
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: { opacity: 1, height: 'auto' },
              closed: { opacity: 0, height: 0 }
            }}
            transition={{ duration: 0.5 }}
            className={cx('flex flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between' >
              <label onClick={()=> console.log(errors.password) } className='text-xs text-gray-500 font-semibold' >{t("USERS.MODAL.PASSWORD")}</label>
              { errors.password && <label className='text-xs text-red-700 font-semibold' >{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("USERS.MODAL.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", {
                required: isEditPassword ? "required" : false,
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}
            />
            <div className='px-3.5' >
              <p className='text-xs text-gray-500' >{errors.password ? errors.password.message !== "required" && errors.password.message : null }</p>
            </div>
          </motion.div>
        </div>
        <div className='mt-4 flex items-center gap-2 p-2 bg-blue-50 text-blue-600 rounded-lg border border-blue-200'>
          <IoMdInformationCircleOutline  className="w-5 h-5 text-blue-500 font-bold" />
          <p className='text-xs'>
            Les champs avec un <span className='text-red-500'>*</span> à côté sont obligatoires.
          </p>
        </div>
        <div className='flex justify-between mt-6' >
          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled} onChange={()=> setEnabled(!enabled) } />
            <div className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("USERS.MODAL.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
            <div className='border border-azure rounded-lg h-9 w-32 relative' >
              <div className='loader'/>
            </div>
            :
            <input
              type="submit"
              onClick={()=> setIsUserSubmit(true) }
              value={t("COMMON.SAVE")}
              className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light'
            />
          }
        </div>
      </form>
    </div>
  )
}

function UpdateUserModal({userData, user}) {

  const rolesInputRef = useRef(null);
  const clientsInputRef = useRef(null);
  const {register, handleSubmit, watch, setValue, formState: {errors}} = useForm();
  const {t, i18n} = useTranslation();
  const [roleInput, setRoleInput] = useState("");
  const [isRolesPickerOpen, setIsRolesPickerOpen] = useState(false);
  const [selectedRolesObj, setSelectedRolesObj] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [filtredRoles, setFiltredRoles] = useState([]);
  const [isUserSubmit, setIsUserSubmit] = useState(false);
  const [isEmailValide, setIsEmailValide] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [isEditPassword, setIsEditPassword] = useState(false);
  const [isClientListOpen, setIsClientListOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState({});
  const [clientsList, setClientsList] = useState([]);
  const [clientSearchInput, setClientSearchInput] = useState("");
  const [userInfo, setUserInfo] = useState({});

  const [loading, setLoading] = useState(false);
  const [errorCode, setErrorCode] = useState("");
  const [userUpdated, setUserUpdated] = useState(false);

  const validateEmail = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsEmailValide(regex.test(email));
  };

  const addOrRomoveRole = (item) => {
    if (selectedRoles.includes(item.id)) {
      setSelectedRoles(selectedRoles.filter(it => it !== item.id));
      setSelectedRolesObj(selectedRolesObj.filter(it => it.id !== item.id));
    } else {
      setSelectedRoles(oldArray => [...oldArray, item.id]);
      setSelectedRolesObj(oldArray => [...oldArray, item]);
    }
    onTypeRole("");
  }

  const selectClient = (item) => {
    setSelectedClient(item);
    setIsClientListOpen(false);
  }

  const removeDiacritics = (str) => {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  };
  const onTypeRole = (value)=>{
    setRoleInput(value);
    if(value.length > 0){
      // openCausesPicker();
      let modifierVal = removeDiacritics(value).toLowerCase();
      const filtered = allRoles.filter( role => removeDiacritics(role.name).toLowerCase().includes(modifierVal) );
      setFiltredRoles(filtered);
    }else{
      setFiltredRoles(allRoles);
    }
  }

  const onSubmit = (data)=>{
    if(selectedRoles.length === 0) return ;
    setLoading(true);
    const formData = new FormData();

    const userNewData = {
      id: userData.id,
      username: data.userName,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      enabled: enabled,
      password: isEditPassword ? data.password : null,
      roles: selectedRoles,
      tenant: user.tenantId
    }

    formData.append('userDto', new Blob([JSON.stringify(userNewData)], { type: 'application/json' }));

    updateTenantUser(formData, userData.id)
      .then((response)=> setUserUpdated(true) )
      .catch((err)=>{ console.log(err); setErrorCode(err.response.data.message) })
      .finally(()=> setLoading(false) );
  }

  useEffect(()=>{
    if(allRoles.length > 0){
      setFiltredRoles(allRoles);
    }
  },[allRoles]);

  useEffect(()=>{
    getAllTenantRoles()
      .then((data)=> setAllRoles(data) )
      .catch((err)=> console.log(err) )
  },[]);

  useEffect(()=>{
    if(userInfo && Object.keys(userInfo).length > 0){
      setValue('lastName', userInfo.lastName);
      setValue('firstName', userInfo.firstName);
      setValue('email', userInfo.email);
      setValue('userName', userInfo.username.split('_')[1]);
      setSelectedRolesObj(userInfo.roles);
      setSelectedRoles(userInfo.roles.map(it=>it.id));
      setEnabled(userInfo.enabled);
      validateEmail(userInfo.email);
      userInfo.tenant && setSelectedClient(userInfo.tenant);
    }
  },[userInfo]);

  useEffect(() => {
    if(userData && Object.keys(userData).length > 0){
      getUserById(userData.id)
        .then((data)=> setUserInfo(data) )
        .catch((err)=> console.log(err) );
    }
  }, [userData]);

  const handleClickOutside = (event) => {
    if (rolesInputRef.current && !rolesInputRef.current.contains(event.target)) {
      setIsRolesPickerOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  if(userUpdated){
    return(
      <div className='min-w-[35vw] flex flex-col items-center justify-center gap-5' >
        <FiCheckCircle size={84} className='text-green-700 ' />
        <h1 className='text-center text-xl font-semibold mb-2' >{t("USERS.MODAL.USER_UPDATED_MSG")}</h1>
      </div>
    )
  }

  return(
    <div className='max-w-[45vw] min-w-[35vw]' >
      <ToastContainer />
      { errorCode.length > 0 && <p className='text-sm font-semibold text-[#D6323A] mb-5' >{t(`ERRORS.${errorCode}`)}</p>}
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col w-full'>
        <div className={cx('grid gap-3 mb-3 grid-cols-2')}>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.LAST_NAME")} *</label>
              {errors.lastName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.LAST_NAME")}
              {...register("lastName", {required: true})}
            />
          </div>
          <div className='flex flex-col'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.FIRST_NAME")} *</label>
              {errors.firstName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.FIRST_NAME")}
              {...register("firstName", {required: true})}
            />
          </div>
        </div>
        <div className="flex items-center gap-3 mb-3">
          <div className='flex flex-col col-span-1'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.USER_NAME")} *</label>
              {errors.userName &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <div className='border rounded-lg px-2 py-1.5 text-sm' >
              <label className="text-gray-500" >
                {user?.username.split('_')[0] + "_"}
              </label>
              <input
                // className='border rounded-lg px-2 py-1.5 text-sm'
                placeholder={t("COMMON.USER_NAME")}
                {...register("userName", {required: true})}
              />
            </div>
          </div>
          <div className='flex flex-col relative w-full'>
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.EMAIL")} *</label>
              {errors.email ?
                errors.email.type === "required"
                  ? <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>
                  : <label className='text-xs text-red-700 font-semibold'>{t("ERRORS.EMAIL_INVALID")}</label>
                : null
              }
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.EMAIL")}
              {...register("email", {required: true, pattern: /^\S+@\S+$/i})}
              onChange={(e) => validateEmail(e.target.value)}
            />
            {
              isEmailValide
                ? <FiCheckCircle size={16} className='text-green-700 absolute right-4 top-[1.75rem]'/>
                : <FiXOctagon size={16} className='text-gray-500 absolute right-4 top-[1.75rem]'/>
            }
          </div>
        </div>
        <div className='flex flex-col mb-3 relative z-10'>
          <div className='px-2.5 mb-0.5 flex items-center justify-between'>
            <label className='text-xs text-gray-500 font-semibold'>{t("COMMON.ROLE")} *</label>
            {(isUserSubmit && selectedRoles.length === 0) ?
              <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label> : null}
          </div>
          <div className='flex flex-col w-full lg:mb-0 mb-3 relative z-20 select-none' ref={rolesInputRef}>
            <div onClick={() => setIsRolesPickerOpen(!isRolesPickerOpen)}
                 className={cx('border rounded-lg text-sm px-2 py-1.5 flex justify-between items-center bg-white cursor-pointer')}>
              <div className='flex max-w-[95%] overflow-hidden'>
                <span className='whitespace-nowrap overflow-hidden text-ellipsis block'>
                  {
                    selectedRolesObj.length === 0
                      ? <span className='text-gray-500'>{t("COMMON.ROLE")} *</span>
                      : selectedRolesObj.map((it) => it.name).join(', ')
                  }
                </span>
              </div>
              {
                isRolesPickerOpen
                  ? <FiChevronUp size={18} color='#000' className='cursor-pointer'
                                 onClick={() => setIsRolesPickerOpen(false)}/>
                  : <FiChevronDown size={18} color='#000' className='cursor-pointer'
                                   onClick={() => setIsRolesPickerOpen(true)}/>
              }
            </div>
            {
              isRolesPickerOpen &&
              <div
                className='border p-2 text-sm rounded-lg mt-1 w-full shadow max-h-[15rem] bg-white z-10 overflow-auto absolute top-[2rem]'>
                <ul>
                  <li>
                    <input
                      type='text'
                      placeholder={t("COMMON.SEARCH")}
                      value={roleInput}
                      onChange={(e) => onTypeRole(e.target.value)}
                      className='border w-full rounded-lg text-sm px-4 py-1 mb-2'
                    />
                  </li>
                  {
                    filtredRoles.map((it) => {
                        if (it.id === 1) {
                          return (
                            selectedRoles.some(id => id !== 1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-purple-light text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        } else {
                          return (
                            selectedRoles.includes(1)
                              ?
                              <li key={it.id}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg bg-gray-100')}>
                                {it.name}
                              </li>
                              :
                              <li key={it.id} onClick={() => addOrRomoveRole(it)}
                                  className={cx('p-1 px-2 mb-0.5 rounded-lg cursor-pointer hover:bg-sky-100 hover:text-black', {'bg-purple-light text-white': selectedRoles.includes(it.id)})}>
                                {it.name}
                              </li>
                          )
                        }
                      }
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className='border p-2 rounded-lg'>
          <div className='flex items-center justify-between'>
            <p className='text-gray-500 text-sm font-semibold'>{t("COMMON.EDIT")} {t("COMMON.PASSWORD")}</p>
            <div className='border rounded-full p-1 cursor-pointer' onClick={() => setIsEditPassword(!isEditPassword)}>
              <FiX
                className={cx('transition duration-300', {'rotate-0': isEditPassword, 'rotate-45': !isEditPassword})}/>
            </div>
          </div>
          <motion.div
            initial="closed"
            animate={isEditPassword ? "open" : "closed"}
            variants={{
              open: {opacity: 1, height: 'auto'},
              closed: {opacity: 0, height: 0}
            }}
            transition={{duration: 0.5}}
            className={cx('flex flex-col', {'mt-3': isEditPassword})}
          >
            <div className='px-2.5 mb-0.5 flex items-center justify-between'>
              <label onClick={() => console.log(errors.password)}
                     className='text-xs text-gray-500 font-semibold'>{t("COMMON.PASSWORD")}</label>
              {errors.password &&
                <label className='text-xs text-red-700 font-semibold'>{t("COMMON.REQUIRED_ERROR")}</label>}
            </div>
            <input
              className='border rounded-lg px-2 py-1.5 text-sm'
              placeholder={t("COMMON.PASSWORD")}
              // {...register("password", { required: false })}
              {...register("password", {
                required: isEditPassword ? "required" : false,
                minLength: {
                  value: 8,
                  message: "Le mot de passe doit contenir au moins 8 caractères"
                },
                maxLength: {
                  value: 16,
                  message: "Le mot de passe ne doit pas dépasser 16 caractères"
                },
                pattern: {
                  value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,16}$/,
                  message: "Le mot de passe doit contenir au moins un chiffre, une majuscule, une minuscule et un caractère spécial"
                }
              })}
            />
            <div className='px-3.5'>
              <p
                className='text-xs text-gray-500'>{errors.password ? errors.password.message !== "required" && errors.password.message : null}</p>
            </div>
          </motion.div>
        </div>
        <div className='mt-4 flex items-center gap-2 p-2 bg-blue-50 text-blue-600 rounded-lg border border-blue-200'>
          <IoMdInformationCircleOutline  className="w-5 h-5 text-blue-500 font-bold" />
          <p className='text-xs'>
            Les champs avec un <span className='text-red-500'>*</span> à côté sont obligatoires.
          </p>
        </div>
        <div className='flex justify-between mt-6'>

          <label className="inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" className="sr-only peer" checked={enabled}
                   onChange={() => setEnabled(!enabled)}/>
            <div
              className="relative w-9 h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span
              className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{t("USERS.MODAL.ACTIVE_USER_BTN")}</span>
          </label>

          {
            loading ?
              <div className='border border-azure rounded-lg h-9 w-32 relative flex items-center justify-center'>
                <div className='loader'/>
              </div>
              :
              <input
                onClick={() => setIsUserSubmit(true)}
                type="submit"
                value={t("COMMON.SAVE")}
                className='border border-azure font-semibold cursor-pointer rounded-lg px-4 py-1.5 text-sm text-azure hover:text-white hover:bg-purple-light'
              />
          }
        </div>
      </form>
    </div>
  )
}

export default function Users() {

  const { userPrivileges, user } = useContext(UserContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isNewUsersModalOpen, setIsNewUserModalOpen] = useState(false);
  const [isEditUsersModalOpen, setIsEditUserModalOpen] = useState(false);
  const [editModalData, setEditModalData] = useState({});

  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [sizeOfPage, setSizeOfPage] = useState(10);
  const [searchInput, setSearchInput] = useState("");
  const [isLastPage, setIsLastPage] = useState(false);

  const openNewUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsNewUserModalOpen(true);
  }
  const closeNewUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsNewUserModalOpen(false);
    fetchUsers();
  }

  const openEditUserModal = (data)=>{
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsEditUserModalOpen(true);
    setEditModalData(data);
  }
  const closeEditUserModal = ()=>{
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditUserModalOpen(false);
    setEditModalData({});
    fetchUsers();
  }

  const fetchUsers = async ()=>{
    getUserByTenantByPage(page, sizeOfPage, searchInput)
      .then((data)=>{
        setUsers(data.content);
        setIsLastPage(data.last);
      })
      .catch((err)=> console.log(err) );
  }

  useEffect(() => {
    fetchUsers();
  }, [searchInput]);

  return (
    <div className='mt-6' >
      <div className='flex items-center justify-between mb-4' >
        <h2 className='text-xl font-semibold' >{t("USERS.TITLE")}</h2>
        <div className='flex items-center gap-2' >
          <div className='border rounded-lg py-2.5 px-3 flex items-center justify-between gap-2' >
            <input
              placeholder={t("USERS.SEARCH_PLACEHOLD")}
              className='text-sm min-w-52'
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <FiSearch size={16} className='text-gray-500' />
          </div>
          <button onClick={()=> openNewUserModal() } className='bg-purple-light hover:bg-purple-dark text-white text-sm px-6 py-2.5 rounded-lg font-semibold' >
            {t("USERS.ADD_BTN")}
          </button>
        </div>
      </div>
      <div className='grid grid-cols-4 gap-4' > 
        
        {
          users.map((item)=>
            <div key={item.id} className='group border rounded-lg p-2 flex flex-col items-center relative' >
              <div className='border rounded-full p-2' >
                <FiUser size={48} />
              </div>
              <p className='font-semibold mb-1' >{item.firstName} {item.lastName}</p>
              <p className='font-semibold text-sm text-gray-500' >{item.email}</p>
              <FiEdit2 onClick={()=> openEditUserModal(item) } className='absolute hidden group-hover:block top-2 right-2 hover:text-purple-light cursor-pointer' />
              <FiBarChart2 onClick={()=> navigate(`/user-info/${1}`) } className='absolute hidden group-hover:block top-10 right-2 hover:text-purple-light cursor-pointer' />
            </div>
          )
        }
      </div>
      <Modal isOpen={isNewUsersModalOpen} close={closeNewUserModal} title={t("USERS.ADD_BTN")} >
        <AddUserModal close={closeNewUserModal} user={user} />
      </Modal>
      <Modal isOpen={isEditUsersModalOpen} close={closeEditUserModal} title={t("USERS.MODAL.EDIT_TITLE")} >
        <UpdateUserModal userData={editModalData} close={closeEditUserModal} user={user} />
      </Modal>
    </div>
  )
}
