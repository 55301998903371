import React, {useEffect, useRef, useState} from 'react';
import styles from '../Styles/project.module.scss';

import { CheckBox, ProgressBar, Row } from '../Styles/DefaultComponents';
import { useTranslation } from 'react-i18next';

import { Doughnut, Line } from 'react-chartjs-2';
import { FiAlertTriangle, FiChevronDown, FiChevronUp } from 'react-icons/fi';


const testData = {
  labels: ["2024-01-01", "2024-01-02", "2024-01-03", "2024-01-04"], // Dates
  datasets: [
    {
      label: "Project A",
      data: [5, 6, 7, 8], // Number of tasks finished for Project A
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Project B",
      data: [3, 2, 1, 3], // Number of tasks finished for Project B
      borderColor: "rgb(54, 162, 235)",
      backgroundColor: "rgba(54, 162, 235, 0.5)",
    },
    {
      label: "Project C",
      data: [2, 3, 4, 5], // Number of tasks finished for Project C
      borderColor: "rgb(75, 192, 192)",
      backgroundColor: "rgba(75, 192, 192, 0.5)",
    }
  ],
};

export default function Dashboard() {

  const { t } = useTranslation();
  const projectFilterRef = useRef(null);
  const usersFilterRef = useRef(null);

  const [isProjectsFilterOpen, setIsProjectsFilterOpen] = useState(false);
  const [projectFilter, setProjectFilter] = useState([]);
  const [isUserFilterOpen, setIsUserFilterOpen] = useState(false);
  const [usersFilter, setUsersFilter] = useState([]);
  const [projectSearchInput, setProjectSearchInput] = useState("");
  const [userSearchInput, setUserSearchInput] = useState("");

  const tasks = {
    completedTasks: 18,
    inProgressTasks: 10,
    delayedTasks: 2
  };
  const totalTasks = tasks.completedTasks + tasks.inProgressTasks + tasks.delayedTasks;
  const taskPercentages = {
    completedTasks: Math.round((tasks.completedTasks / totalTasks) * 100),
    inProgressTasks: Math.round((tasks.inProgressTasks / totalTasks) * 100),
    delayedTasks: Math.round((tasks.delayedTasks / totalTasks) * 100)
  };

  const TCdata = {
    datasets: [
      {
        data: [taskPercentages.completedTasks, 100 - taskPercentages.completedTasks],
        backgroundColor: ['#23B301', '#f4fff2'],
        borderWidth: 0,
        cutout: '82%'
      }
    ]
  };
  const TIPdata = {
    datasets: [
      {
        data: [taskPercentages.inProgressTasks, 100 - taskPercentages.completedTasks],
        backgroundColor: ['#7878c7', '#edecf9'],
        borderWidth: 0,
        cutout: '82%'
      }
    ]
  };
  const TDPdata = {
    datasets: [
      {
        data: [taskPercentages.delayedTasks, 100 - taskPercentages.delayedTasks],
        backgroundColor: ['#D6323A', '#fdf4f4'],
        borderWidth: 0,
        cutout: '82%'
      }
    ]
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: false
      },
      legend: {
        display: false
      }
    }
  };

  const toggleCheckProjectFilter = (item)=>{
    if(projectFilter.some(it => it.id === item.id)){
      setProjectFilter(projectFilter.filter(it=> it.id !== item.id ));
    }else{
      setProjectFilter(oldArray => [...oldArray, item]);
    }
  }

  const toggleCheckUsersFilter = (item) =>{
    if(usersFilter.some(it => it.id === item.id)){
      setUsersFilter(usersFilter.filter(it=> it.id !== item.id ));
    }else{
      setUsersFilter(oldArray => [...oldArray, item]);
    }
  }

  const handleClickOutside = (event) => {
    if (projectFilterRef.current && !projectFilterRef.current.contains(event.target)) {
      setIsProjectsFilterOpen(false);
    }
    if (usersFilterRef.current && !usersFilterRef.current.contains(event.target)) {
      setIsUserFilterOpen(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const userProgressCard = ()=>(
    <div className='border rounded-lg p-2' >
      <div className='flex items-center justify-between text-sm font-semibold mb-2' >
        <div className='flex items-center gap-2' >
          <div className='w-8 h-8 bg-gray-300 rounded-full' ></div>
          <p>Adil Stifi</p>
        </div>
        <p className='text-gray-500' >30 {t("COMMON.TASK_TYPE.TASK")}</p>
      </div>
      <ProgressBar progress={taskPercentages.completedTasks} />
      <div className='flex items-center gap-2 mt-2' >
        <FiAlertTriangle color='#D6323A' />
        <p className='font-semibold text-xs text-[#D6323A]' >{tasks.delayedTasks} {t("DASHBOARD.FILTERS.SUB_TITLES.TASK_DELAYED")}</p>
      </div>
    </div>
  )

  return (
    <div className='mt-6' >
      <div className='flex items-center justify-between mb-5' >
        <p className='text-4xl font-bold' >{t("DASHBOARD.TITLE")}</p>
        <div className='flex items-center gap-4' >
          <div ref={projectFilterRef} className='relative' >
            <button onClick={()=> setIsProjectsFilterOpen(!isProjectsFilterOpen) } className='relative text-left border rounded-lg text-sm pl-2 w-52 py-2' >
              { projectFilter.length > 0 
                ? <span>{projectFilter.map(it=> it.name).join(', ')}</span>
                : <span className='text-gray-500' >{t("DASHBOARD.FILTERS.PROJECT.TITLE")}</span>
              }
              <span className='absolute right-3 top-[50%] translate-y-[-50%]' >
                { isProjectsFilterOpen 
                  ? <FiChevronUp className='text-gray-500' size={16} />
                  : <FiChevronDown className='text-gray-500' size={16} />
                }
              </span>
            </button>
            { isProjectsFilterOpen &&
              <ul className='customShadow bg-white rounded-lg absolute top-10 left-0 w-full z-20' >
                <li className='border rounded-md px-2 m-2' >
                  <input
                    placeholder={t("ALL_PROJECTS.SEARCH_PLACEHOLD")}
                    className='text-sm mb-1'
                    value={projectSearchInput}
                    onChange={(e)=> setProjectSearchInput(e.target.value) }
                  />
                </li>
                {
                  Array.from({ length: 3 }, (_, i) => i).map((it)=> 
                    <li key={it} className='p-2 hover:bg-gray-100 rounded-lg' >
                      <Row>
                        <CheckBox isChecked={projectFilter.some(item => item.id === it)} toggleCheck={()=> toggleCheckProjectFilter({id: it, name: `test ${it}`}) } />
                        <div className='text-sm ml-2' >
                          test {it}
                        </div>
                      </Row>
                    </li> 
                  )
                }
              </ul>
            }
          </div>
          <div ref={usersFilterRef} className='relative' >
            <button onClick={()=> setIsUserFilterOpen(!isUserFilterOpen) } className='relative text-left border rounded-lg text-sm pl-2 w-52 py-2' >
              { usersFilter.length > 0 
                ? <span>{usersFilter.map(it=> it.name).join(', ')}</span>
                : <span className='text-gray-500' >{t("DASHBOARD.FILTERS.USER.TITLE")}</span>
              }
              <span className='absolute right-3 top-[50%] translate-y-[-50%]' >
                { isUserFilterOpen 
                  ? <FiChevronUp className='text-gray-500' size={16} />
                  : <FiChevronDown className='text-gray-500' size={16} />
                }
              </span>
            </button>
            { isUserFilterOpen &&
              <ul className='customShadow bg-white rounded-lg absolute top-10 left-0 w-full z-20' >
                <li className='border rounded-md px-2 m-2' >
                  <input
                    placeholder={t("DASHBOARD.FILTERS.USER.SEARCH")}
                    className='text-sm mb-1'
                    value={userSearchInput}
                    onChange={(e)=> setUserSearchInput(e.target.value) }
                  />
                </li>
                {
                  Array.from({ length: 3 }, (_, i) => i).map((it)=> 
                    <li key={it} className='p-2 hover:bg-gray-100 rounded-lg' >
                      <Row>
                        <CheckBox isChecked={usersFilter.some(item => item.id === it)} toggleCheck={()=> toggleCheckUsersFilter({id: it, name: `test ${it}`}) } />
                        <div className='text-sm ml-2' >
                          test {it}
                        </div>
                      </Row>
                    </li> 
                  )
                }
              </ul>
            }
          </div>
        </div>
      </div>
      <div className='flex justify-between gap-5' >
        <div className='border rounded-lg p-2 w-1/2' >
          <p className='font-semibold text-xs text-gray-600 mb-4' >{t("DASHBOARD.FILTERS.SUB_TITLES.TASK_COMPLETED")}</p>
          <div className='flex items-center gap-5' >
            <div style={{ width: '100px', height: '100px', position: 'relative' }}>
              <Doughnut data={TCdata} options={options} />
              <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-[#23B301]' >
                {taskPercentages.completedTasks} %
              </span>
            </div>
            <div>
              <span className='text-5xl font-bold text-[#23B301]' >{tasks.completedTasks}</span>
              <span className='text-gray-400 font-bold' >/ {totalTasks} {t("COMMON.TASKS")}</span>
            </div>
          </div>
        </div>
        <div className='border rounded-lg p-2 w-1/2' >
          <p className='font-semibold text-xs text-gray-600 mb-4' >{t("DASHBOARD.FILTERS.SUB_TITLES.TASK_INPROGRESS")}</p>
          <div className='flex items-center gap-5' >
            <div style={{ width: '100px', height: '100px', position: 'relative' }}>
              <Doughnut data={TIPdata} options={options} />
              <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-purple-dark' >
                {taskPercentages.inProgressTasks} %
              </span>
            </div>
            <div>
              <span className='text-5xl font-bold text-purple-dark' >{tasks.inProgressTasks}</span>
              <span className='text-gray-400 font-bold' >/ {totalTasks} {t("COMMON.TASKS")}</span>
            </div>
          </div>
        </div>
        <div className='border rounded-lg p-2 w-1/2' >
          <p className='font-semibold text-xs text-gray-600 mb-4' >{t("DASHBOARD.FILTERS.SUB_TITLES.TASK_DELAYED")}</p>
          <div className='flex items-center gap-5' >
            <div style={{ width: '100px', height: '100px', position: 'relative' }}>
              <Doughnut data={TDPdata} options={options} />
              <span className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-xl font-semibold text-[#D6323A]' >
                {taskPercentages.delayedTasks} %
              </span>
            </div>
            <div>
              <span className='text-5xl font-bold text-[#D6323A]' >{tasks.delayedTasks}</span>
              <span className='text-gray-400 font-bold' >/ {totalTasks} {t("COMMON.TASKS")}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='grid grid-cols-3 mt-6 gap-5' >
        <div className='col-span-2 border rounded-lg p-2' >
          <Line 
            data={testData} 
            options={{
              maintainAspectRatio: false,
            }}
          />
        </div>
        <div className='col-span-1 border rounded-lg p-2' >
          <p className='font-semibold text-xs text-gray-600 mb-4' >{t("DASHBOARD.FILTERS.SUB_TITLES.TEAM_PROGRESS")}</p>
          <div className='flex flex-col gap-2 overflow-x-hidden overflow-y-auto' >
            { Array.from({ length: 3 }, (_, i) => i).map(()=> userProgressCard() )}
          </div>
        </div>
      </div>
    </div>
  )
}
