import React, {useContext, useRef, useState, useEffect, useCallback} from 'react';
import styles from '../Styles/myTasks.module.scss';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import cx from 'classnames';
import {
  FiChevronDown,
  FiChevronUp,
  FiClock,
  FiEdit,
  FiEdit2,
  FiFolder,
  FiPlusCircle,
  FiTrash,
  FiUser
} from 'react-icons/fi';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import DatePicker from "react-datepicker"; 
import { Controller, useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';

import { IoMdInformationCircleOutline } from "react-icons/io";
import SideModal from './SideModal';
import { TaskModal } from './Overview';
import { AnimatePresence, color } from 'framer-motion';
import {
  createSprint,
  deleteSprint,
  getAllSprint,
  getAllSprintStatus,
  reOrderSprints,
  updateSprint
} from "../services/sprintService";
import {
  getAllFinishedTasks,
  getAllTasks,
  getTaskById,
  updateTaskSprint,
  updateTaskStatus
} from "../services/taskService";
import Modal from "./Modal";
import {getAllUsers} from "../services/usersService";
import {EnumsColors, SprintStatus, TaskStatus} from "../Ressources/enums";
import {useParams} from "react-router-dom";

const columnsDataTest = [
  { id: 'planned', name: "Planned" },
  { id: 'inDevelopment', name: "In Development" },
  { id: 'readyForTest', name: "Ready for Test" },
  { id: 'readyForReview', name: "Ready for Review" },
  { id: 'sprint_test', name: "Sprint 1" },
  { id: 'sprint_Z', name: "Sprint 2" },
];

const myTasks = [
  { id: 1, task: 'Rédiger une proposition de projet', assignedTo: 'Adil Stifi', dueDate: '04-01-2024', sprintStatus: "Planned", status: "Pas commencé", priority: "Low", subTasks: [{id: 1, subTask: "test 1", status: "Pas commencé"} ,{id: 2, subTask: "test 2", status: "En cours"}] },
  { id: 2, task: 'Planifier la réunion de lancement', assignedTo: 'Adil Stifi', dueDate: '03-01-2024', sprintStatus: "In Development", status: "En cours", priority: "Medium", subTasks: [] },
  { id: 3, task: 'Consulter les résultats de la recherche', assignedTo: 'Adil Stifi', dueDate: '17-04-2024', sprintStatus: "Ready for Review", status: "Terminé", priority: "Hight", subTasks: [] }
];


const categorizeTasks = (tasks) => {
  const categories = {
    today: [],
    tomorrow: [],
    thisWeek: [],
    later: []
  };

  tasks.forEach(task => {
    const dueDate = moment(task.dueDate, "DD-MM-YYYY");
    const today = moment();
    const tomorrow = moment().add(1, 'days');

    if (dueDate.isSame(today, 'day')) {
      categories.today.push(task);
    } else if (dueDate.isSame(tomorrow, 'day')) {
      categories.tomorrow.push(task);
    } else if (dueDate.isSameOrAfter(today.startOf('week')) && dueDate.isSameOrBefore(today.endOf('week').subtract(2, 'days'))) {
      categories.thisWeek.push(task);
    } else {
      categories.later.push(task);
    }
  });

  return categories;
};

function TaskContainer({provided, task, openTaskModal, fetchTasks, isDragging}){
  
  
  const {t} = useTranslation();
  const statusMenuRef = useRef(null);
  const subTasksRef = useRef(null);

  const [subTasksHeight, setSubTasksHeight] = useState(0);
  const [isSubTasksShowen, setIsSubTasksShowen] = useState(true);
  const [isStatusMenuOpen, setIsStatusMenuOpen] = useState(false);

  const changeStatus = (e, newStatus)=>{
    e.stopPropagation();
    updateTaskStatus({"status": newStatus} ,task.id)
      .then((res)=> {
        fetchTasks();
      })
      .catch((err)=> console.log(err) );
    setIsStatusMenuOpen(false);
  }

  useEffect(() => {
    if (subTasksRef.current) {
      const height = subTasksRef.current.offsetHeight;
      setSubTasksHeight(height-10);
    }
  }, [isSubTasksShowen, task]);

  const handleClickOutside = (event) => {
    if (statusMenuRef.current && !statusMenuRef.current.contains(event.target)) {
      setIsStatusMenuOpen(false);
    }
  }; 
  
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return(
    <div className="relative flex flex-col items-end"  >
      <div
        onClick={()=> openTaskModal(task) }
        ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={styles.task}>
        <div className='flex items-center justify-between gap-1.5 text-xs text-gray-500 mb-3' >
          <div className='flex items-center gap-1.5 text-xs' >
            <FiFolder size={12}  />
            <p>{t('ALL_PROJECTS.ADD_MODAL.NAME')}</p>
          </div>
          <div className='flex items-center gap-1.5 text-xs truncate max-w-[40%]' >
            <FiClock size={12} className='w-5' />
            <span className='truncate' >{moment(task?.shouldStartAt).add(task.estimation, 'day').format('DD-MM-YYYY')}</span>
          </div>
        </div>
        <p className='font-semibold mb-4' >{task.name}</p>
        <div className='flex items-center gap-2 text-gray-500' >
          <div
            style={{color: EnumsColors[task.priority].text, backgroundColor: EnumsColors[task.priority].bg}}
            className={styles.priority}
          >
            {t(`COMMON.TASK_PRIORITY.${task.priority}`)}
          </div>
          <span>•</span>
          <div className='relative w-max' >
            <button
              onClick={(e)=>{ e.stopPropagation(); setIsStatusMenuOpen(true)}}
              className={styles.status}
              style={{color: EnumsColors[task.status].text, backgroundColor: EnumsColors[task.status].bg}}
            >
              {t(`COMMON.TASK_STATUS.${task.status}`)}
            </button>
            {
              isStatusMenuOpen &&
              <div className={styles.floatStatusMenu} ref={statusMenuRef} >
                <ul>
                  <li className={styles.currStatus} >{t(`COMMON.TASK_STATUS.${task.status}`)}</li>
                  {
                    TaskStatus.map((it) =>
                      <li
                        key={it}
                        onClick={(e) => changeStatus(e, it)}
                        className={cx(styles.status,{[styles.progress]: it === "IN_PROGRESS", [styles.finish]: it === "FINISHED", [styles.pause]: it === "PAUSED"})}>
                        • {t(`COMMON.TASK_STATUS.${it}`)}
                      </li>
                    )
                  }
                </ul>
              </div>
            }
          </div>
        </div>
        <div className={styles.taskEditIcon} >
          <FiEdit2/>
        </div>
        { task?.subTasks.length > 0 &&
          <div onClick={(e)=>{ e.stopPropagation(); setIsSubTasksShowen(!isSubTasksShowen) }} className='absolute z-20 w-6 h-6 bg-white bottom-[-12px] right-2 border rounded-full flex items-center justify-center hover:border-purple-light hover:text-purple-light' >
            { isSubTasksShowen ? <FiChevronUp/> : <FiChevronDown/>}
          </div>
        }
        { !isDragging ? isSubTasksShowen && <div className='absolute border bottom-0 left-[10%] translate-y-[100%]' style={{height: subTasksHeight+'px'}} /> : null}
      </div>
      { !isDragging ? isSubTasksShowen &&
        <div className='w-[80%]' ref={subTasksRef} >
          {
            task?.subTasks.map((it)=>
              <div key={it.id} onClick={()=> openTaskModal(task) } className='bg-white border p-2 my-1 rounded-lg w-full relative cursor-pointer hover:bg-gray-100' >
                <div className='absolute top-[50%] border w-[12%] left-[-12%]' />
                <div className='flex items-center justify-between' >
                  <p className='text-sm font-semibold' >{it.name}</p>
                  {/* <p className='text-xs font-semibold' >{it.status}</p> */}
                </div>
              </div>
            )
          }
        </div>
        : null
      }
    </div>
  )
}

const KanbanColumn = React.forwardRef((props, ref) => {
  const {
    index,
    title,
    item,
    tasks,
    bgColor,
    titleColor,
    droppableId,
    openTaskModal,
    columnRefs,
    columnIndex,
    firstColumnToClose,
    setFirstColumnsToClose,
    openEditSprintModal,
    fetchSprints,
    fetchTasks,
    isDragging
  } = props;

  const internalRef = useRef(null);
  const columnRef = ref || internalRef;
  const [isClosed, setIsClosed] = useState(false);
  const resizeTimeoutRef = useRef(null);
  const isMountedRef = useRef(true);
  const animationTimeoutRef = useRef(null);

  const {t } = useTranslation();


  const submitDeleteSprint = () => {
    Swal.fire({
      title: t("SPRINT.DELETE_MODAL.ARE_YOU_SURE"),
      text: t("SPRINT.DELETE_MODAL.YOU_WONT_BE_ABLE_TO_REVERT_THIS"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("SPRINT.DELETE_MODAL.YES_DELETE_IT")
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('Deleting sprint with ID:', item.id);
        deleteSprint(item.id)
          .then((res) => {
            console.log('Delete response:', res);
            Swal.fire({
              title: t("SPRINT.DELETE_MODAL.DELETED"),
              text: t("SPRINT.DELETE_MODAL.YOUR_SPRINT_HAS_BEEN_DELETED"),
              icon: "success"
            });
            fetchSprints();
          })
          .catch((err) => {
            console.log('Error deleting sprint:', err);
            Swal.fire({
              icon: "error",
              title: t("SPRINT.DELETE_MODAL.ERROR"),
              text: t("SPRINT.DELETE_MODAL.ERROR_DELETING_SPRINT") + ': ' + err.message
            });
          });
      }
    });
  };

  const getContainerWidth = () => {
    const container = columnRef.current.closest(`.${styles.kanbanContainer}`);
    if (!container) return 0;
    
    const computedStyle = window.getComputedStyle(container);
    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);
    const marginLeft = parseFloat(computedStyle.marginLeft);
    const marginRight = parseFloat(computedStyle.marginRight);
    
    const rect = container.getBoundingClientRect();
    const availableWidth = rect.width - (paddingLeft + paddingRight + marginLeft + marginRight);
    
    // console.log('Container measurements:', {
    //   totalWidth: rect.width,
    //   padding: { left: paddingLeft, right: paddingRight },
    //   margin: { left: marginLeft, right: marginRight },
    //   availableWidth
    // });

    return availableWidth;
  };

  const openColumn = () => {
    if (!columnRef?.current || !columnRefs?.current) return;
    
    const OPEN_COLUMN_WIDTH = 260;
    const CLOSED_COLUMN_WIDTH = 30;
    const COLUMN_GAP = 16;
    const ANIMATION_DELAY = 50; // Shorter animation delay for smoother transitions
    // Get the kanban container width instead of window width
    const containerWidth = getContainerWidth();
    if (containerWidth === 0) return;
    
    // console.log('containerWidth', containerWidth);
    
    // Clear any pending animations
    if (animationTimeoutRef.current) {
      clearTimeout(animationTimeoutRef.current);
    }

    let totalWidthNeeded = 0;
    let openedColumns = [];
    
    // Calculate current state and total width needed
    columnRefs.current.forEach((ref, idx) => {
      if (!ref?.current) return;
      
      const width = ref.current.getBoundingClientRect().width;
      const isColumnOpen = width > CLOSED_COLUMN_WIDTH;
      
      // console.log('Column', idx, 'is open:', isColumnOpen, 'width:', width);
      

      if (isColumnOpen && idx !== columnIndex) {
        openedColumns.push(idx);
        totalWidthNeeded += OPEN_COLUMN_WIDTH + COLUMN_GAP;
      } else if (!isColumnOpen) {
        totalWidthNeeded += CLOSED_COLUMN_WIDTH + COLUMN_GAP;
      }
    });

    // Add width for the column we want to open
    totalWidthNeeded += OPEN_COLUMN_WIDTH;
    // console.log('Total width needed:', totalWidthNeeded);
    
    // Check if we need to close any columns to make space
    if (totalWidthNeeded > containerWidth) {
      // Start closing columns from the end until we have enough space
      const columnsToClose = [];
      let currentWidth = totalWidthNeeded;
      // console.log('Current width:', currentWidth);
      
      // First try to close columns after the current one
      for (let i = columnRefs.current.length - 1; i > columnIndex; i--) {
        if (currentWidth > containerWidth && openedColumns.includes(i)) {
          columnsToClose.push(i);
          currentWidth -= (OPEN_COLUMN_WIDTH - CLOSED_COLUMN_WIDTH);
          if (currentWidth <= containerWidth) break;
        }
      }

      // If still need space, close columns before the current one from right to left
      if (currentWidth > containerWidth) {
        for (let i = columnIndex - 1; i >= 0; i--) {
          if (currentWidth > containerWidth && openedColumns.includes(i)) {
            columnsToClose.push(i);
            currentWidth -= (OPEN_COLUMN_WIDTH - CLOSED_COLUMN_WIDTH);
            if (currentWidth <= containerWidth) break;
          }
        }
      }

      // Close columns sequentially
      if (columnsToClose.length > 0) {
        columnsToClose.forEach((colIndex, i) => {
          animationTimeoutRef.current = setTimeout(() => {
            setFirstColumnsToClose(colIndex);
          }, i * ANIMATION_DELAY);
        });

        // Open this column after all closes are initiated
        animationTimeoutRef.current = setTimeout(() => {
          setIsClosed(false);
        }, columnsToClose.length * ANIMATION_DELAY);
      }
    } else {
      // We have enough space, open immediately
      setIsClosed(false);
    }
  };

  // Cleanup animation timeouts
  useEffect(() => {
    return () => {
      if (animationTimeoutRef.current) {
        clearTimeout(animationTimeoutRef.current);
      }
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }
      isMountedRef.current = false;
    };
  }, []);

  // Update effect to handle column closure state
  useEffect(() => {
    const handleResize = () => {
      if (resizeTimeoutRef.current) {
        clearTimeout(resizeTimeoutRef.current);
      }

      resizeTimeoutRef.current = setTimeout(() => {
        if (!isMountedRef.current || !columnRef?.current || !columnRefs?.current) return;
        
        try {
          const OPEN_COLUMN_WIDTH = 260;
          const CLOSED_COLUMN_WIDTH = 30;
          const COLUMN_GAP = 16;
          
          const containerWidth = getContainerWidth();
          if (containerWidth === 0) return;

          let totalWidthNeeded = 0;
          let openedColumns = [];
          
          // Calculate current state and total width needed
          columnRefs.current.forEach((ref, idx) => {
            if (!ref?.current) return;
            
            const width = ref.current.getBoundingClientRect().width;
            const isColumnOpen = width > CLOSED_COLUMN_WIDTH;
            
            if (isColumnOpen && idx !== columnIndex) {
              openedColumns.push(idx);
              totalWidthNeeded += OPEN_COLUMN_WIDTH + COLUMN_GAP;
            } else if (!isColumnOpen) {
              totalWidthNeeded += CLOSED_COLUMN_WIDTH + COLUMN_GAP;
            }
          });

          // Add width for this column if it's open
          if (!isClosed) {
            totalWidthNeeded += OPEN_COLUMN_WIDTH;
          }

          // Check if we need to close any columns to make space
          if (totalWidthNeeded > containerWidth) {
            const columnsToClose = [];
            let currentWidth = totalWidthNeeded;
            // console.log('Current width:', currentWidth);
            
            // First try to close columns after the current one
            for (let i = columnRefs.current.length - 1; i > columnIndex; i--) {
              if (currentWidth > containerWidth && openedColumns.includes(i)) {
                columnsToClose.push(i);
                currentWidth -= (OPEN_COLUMN_WIDTH - CLOSED_COLUMN_WIDTH);
                if (currentWidth <= containerWidth) break;
              }
            }

            
            if (currentWidth > containerWidth) {
              for (let i = columnIndex - 1; i >= 0; i--) {
                if (currentWidth > containerWidth && openedColumns.includes(i)) {
                  columnsToClose.push(i);
                  currentWidth -= (OPEN_COLUMN_WIDTH - CLOSED_COLUMN_WIDTH);
                  if (currentWidth <= containerWidth) break;
                }
              }
            }

            // Close columns sequentially
            if (columnsToClose.length > 0) {
              columnsToClose.forEach((colIndex, i) => {
                setTimeout(() => {
                  setFirstColumnsToClose(colIndex);
                }, i * 50);
              });

              // Open this column after all closes are initiated
              setTimeout(() => {
                setIsClosed(false);
              }, columnsToClose.length * 50);
            }
          }
        } catch (error) {
          console.error('Error in handleResize:', error);
        }
      }, 150);
    };

    if (columnRef?.current && columnRefs?.current) {
      handleResize();
      window.addEventListener('resize', handleResize);

      return () => {
        if (resizeTimeoutRef.current) {
          clearTimeout(resizeTimeoutRef.current);
        }
        window.removeEventListener('resize', handleResize);
      };
    }
  }, [columnIndex, columnRefs, columnRef]);

  useEffect(()=>{
    if(firstColumnToClose !== null && firstColumnToClose === index ){
      setIsClosed(true);
      setFirstColumnsToClose(null);
    }
  },[firstColumnToClose]);

  return(
    <div ref={columnRef} className={cx(styles.kanbanColumn, {[styles.closed_kanbanColumn]: isClosed, [styles.openned_kanbanColumn]: !isClosed})} style={{backgroundColor: bgColor}}>
      {
        isClosed ?
        <div className={cx('rotate-[-90deg] w-max relative z-20', styles.header)} style={{color: titleColor}} >
          <span className='cursor-pointer whitespace-nowrap w-max' onClick={()=> openColumn() } >{title}</span>
        </div>
        :
        <div>
          <div className={styles.header} style={{color: titleColor}}>
            <span className='cursor-pointer truncate w-[70%]' title={title} onClick={()=> setIsClosed(true) } >• {title}</span>
            <div className="flex items-center gap-0" >
              <FiTrash
                size={16}
                className='cursor-pointer hover:text-red-500 hover:animate-bounce w-[25px] pl-1'
                onClick={() => submitDeleteSprint()}
              />
              <FiEdit2
                size={16}
                className='cursor-pointer hover:text-blue-500 hover:animate-bounce w-[25px] pl-1'
                onClick={() => openEditSprintModal(item)}
              />
              <FiPlusCircle
                size={16}
                className='cursor-pointer hover:text-green-500 hover:animate-bounce w-[25px] pl-1'
                onClick={()=> openTaskModal(
                  {name: "", assignee: null, shouldStartAt: "", estimation: 1, sprint: item, status: "UNSTARTED", priority: "LOW", subTasks: []}
                ) }
              />
            </div>
          </div>
          <Droppable droppableId={droppableId}>
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps} className={styles.content}>
                {tasks.map((task, index) => (
                  <Draggable key={task.id} draggableId={task.id.toString()} index={index}>
                    {(provided) => (
                      <TaskContainer
                        provided={provided}
                        task={task}
                        openTaskModal={openTaskModal}
                        fetchTasks={fetchTasks}
                        isDragging={isDragging}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      }
    </div>
  )
})

function KanbanView({ openTaskModal, projectId, columnsData, setColumnsData, tasks, setTasks, openEditSprintModal, fetchSprints, fetchTasks }) {
  const { t } = useTranslation();
  const [firstColumnToClose, setFirstColumnsToClose] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const columnRefs = useRef([]);
  const containerRef = useRef(null);

  // Calculate how many columns can be open based on container width
  const getContainerWidth = useCallback(() => {
    if (!containerRef.current) return 0;
    
    const computedStyle = window.getComputedStyle(containerRef.current);
    const paddingLeft = parseFloat(computedStyle.paddingLeft);
    const paddingRight = parseFloat(computedStyle.paddingRight);
    const marginLeft = parseFloat(computedStyle.marginLeft);
    const marginRight = parseFloat(computedStyle.marginRight);
    
    const rect = containerRef.current.getBoundingClientRect();
    const availableWidth = rect.width - (paddingLeft + paddingRight + marginLeft + marginRight);

    // console.log('KanbanView container measurements:', {
    //   totalWidth: rect.width,
    //   padding: { left: paddingLeft, right: paddingRight },
    //   margin: { left: marginLeft, right: marginRight },
    //   availableWidth
    // });

    return availableWidth;
  }, []);

  const calculateMaxOpenColumns = useCallback(() => {
    const containerWidth = getContainerWidth();
    if (containerWidth === 0) return 0;
    
    const OPEN_COLUMN_WIDTH = 260;
    const COLUMN_GAP = 16;
    
    // Calculate max columns that can fit
    const maxColumns = Math.floor((containerWidth + COLUMN_GAP) / (OPEN_COLUMN_WIDTH + COLUMN_GAP));
    
    // console.log('Max columns calculation:', {
    //   containerWidth,
    //   maxColumns,
    //   totalWidth: maxColumns * (OPEN_COLUMN_WIDTH + COLUMN_GAP) - COLUMN_GAP
    // });

    return maxColumns;
  }, [getContainerWidth]);

  // Initialize refs and handle initial column states
  useEffect(() => {
    if (columnsData && columnsData.length > 0) {
      columnRefs.current = columnsData.map((_, i) => 
        columnRefs.current[i] || React.createRef()
      );

      // Wait for refs to be populated and DOM to be ready
      requestAnimationFrame(() => {
        const maxOpenColumns = calculateMaxOpenColumns();
        const containerWidth = getContainerWidth();
        
        // console.log('Initial layout calculation:', {
        //   maxOpenColumns,
        //   containerWidth,
        //   totalColumns: columnsData.length,
        //   columnsToClose: Math.max(0, columnsData.length - maxOpenColumns)
        // });

        if (maxOpenColumns > 0 && columnsData.length > maxOpenColumns) {
          // Close columns from the end
          for (let i = columnsData.length - 1; i >= maxOpenColumns; i--) {
            setTimeout(() => {
              setFirstColumnsToClose(i);
            }, (columnsData.length - i) * 100);
          }
        }
      });
    }
  }, [columnsData, calculateMaxOpenColumns, getContainerWidth]);

  // Handle container resize
  useEffect(() => {
    const resizeObserver = new ResizeObserver(debounce(() => {
      const maxOpenColumns = calculateMaxOpenColumns();
      const containerWidth = getContainerWidth();
      
      const openColumns = columnRefs.current
        .map((ref, index) => ({
          index,
          width: ref?.current?.getBoundingClientRect().width || 0
        }))
        .filter(col => col.width > 30)
        .map(col => col.index);

      // console.log('Container resize handling:', {
      //   containerWidth,
      //   maxOpenColumns,
      //   currentlyOpen: openColumns.length,
      //   openColumns
      // });

      if (openColumns.length > maxOpenColumns) {
        // Close columns from the end until we fit
        const columnsToClose = openColumns.slice(maxOpenColumns);
        // console.log('Closing columns:', columnsToClose);
        
        columnsToClose.reverse().forEach((columnIndex, i) => {
          setTimeout(() => {
            setFirstColumnsToClose(columnIndex);
          }, i * 100);
        });
      }
    }, 150));

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [calculateMaxOpenColumns, getContainerWidth]);

  function debounce(func, wait) {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  }

  const onDragEnd = async (result) => {
    const { source, destination, type } = result;

    if (!destination) {
      return;
    }

    if (type === "column") {

      let updatedColumnsData = [...columnsData];

      const [movedColumn] = updatedColumnsData.splice(source.index, 1);
      updatedColumnsData.splice(destination.index, 0, movedColumn);

      setColumnsData(updatedColumnsData);

      let newOrder = updatedColumnsData.map((it, index)=>{ return {id: it.id, orderIndex: index+1, name: it.name} })

      await reOrderSprints(newOrder)
        .then((res)=>{
          // console.log(res);
        })
        .catch((err)=>{ console.log(err) });

      return;
    }

    const taskId = tasks[source.droppableId][source.index].id;
    const newSprint = destination.droppableId;

    await updateTaskSprint({"sprintId": newSprint}, taskId)
      .then((res)=> {
        fetchTasks();
      })
      .catch((err)=> console.log(err) );

    const newResult = move(
      tasks[source.droppableId],
      tasks[destination.droppableId],
      source,
      destination
    );
    setTasks({
      ...tasks,
      ...newResult,
    });

    setIsDragging(false);
  };

  const move = (source, destination, droppableSource, droppableDestination) => {
    // console.log(columnsData.find(col => col.id === droppableDestination.droppableId));
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    destClone[droppableDestination.index].sprint.id = columnsData.find(col => col.id === droppableDestination.droppableId).id;
    destClone[droppableDestination.index].sprint.name = columnsData.find(col => col.id === droppableDestination.droppableId).name;
    destClone[droppableDestination.index].sprint.startDate = columnsData.find(col => col.id === droppableDestination.droppableId).startDate;
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  };

  const onDragStart = (result) => {
    setIsDragging(true);
  }

  useEffect(() => {
    if (columnsData.length > 0) {
      columnRefs.current = Array(columnsData.length).fill().map((_, i) => columnRefs.current[i] || React.createRef());
    }
  }, [columnsData.length]);

  return (
    <div ref={containerRef} className={styles.kanbanContainer}>
      <div className={styles.kanbanBoard}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="all-columns" direction="horizontal" type="column">
            {(provided) => (
              <div
                className={styles.kanbanBoard}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {columnsData.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="h-full"
                      >
                        <KanbanColumn
                          ref={columnRefs.current[index]}
                          index={index}
                          item={item}
                          title={item.name}
                          tasks={tasks[item.id] || []}
                          bgColor="#F5FAFC"
                          titleColor="#5A97BD"
                          droppableId={item.id}
                          openTaskModal={openTaskModal}
                          columnRefs={columnRefs}
                          columnIndex={index}
                          firstColumnToClose={firstColumnToClose}
                          setFirstColumnsToClose={setFirstColumnsToClose}
                          openEditSprintModal={openEditSprintModal}
                          fetchSprints={fetchSprints}
                          fetchTasks={fetchTasks}
                          isDragging={isDragging}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

function EditSprintFunction({oldData, close, projectId}){
  const {t} = useTranslation();
  const statusSelectRef = useRef(null);
  const { register,control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      dateRange: [null, null],
    },
  });
  const [name, setName] = useState("");
  const [deadline, setDeadLine] = useState(null);
  const [status, setStatus] = useState({});
  const [statusList, setStatusList] = useState([]);
  const [isStatusSelectOpen, setIsStatusSelectOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sprintData, setSprintData] = useState({
    name: "",
    status: SprintStatus[0],
    projectId: projectId,
    startDate: null,
    endDate: null,
  });

  const changeField = (name, value)=>{
    setSprintData({...sprintData, [name]: value});
  }

  const clickStatus = (it)=>{
    setIsStatusSelectOpen(false);
    changeField("status", it);
  }

  const submit = () => { 
    try {
      setLoading(true); 
      
      const newSprintData = { 
        "name": sprintData.name,
        "status": sprintData.status,
        projectId,
        startDate: sprintData.dateRange[0],
        endDate: sprintData.dateRange[1],
      };
      // console.log("Submitting sprint data:", newSprintData); // Debug log
  
      updateSprint(newSprintData, oldData.id)
        .then((res) => {
          // console.log("Response from updateSprint:", res); // Log the response
          toast.success(t('SPRINT.SPRINT_EDIT_SUCCESS_MSG')); // Success message
          close();
        })
        .catch((err) => {
          // console.log("Error updating sprint:", err); // Debug log
          toast.error(t('SPRINT.SPRINT_EDIT_ERROR_MSG')); // Error message
        })
        .finally(() => setLoading(false));
    } catch (error) {
      // console.log("Error in submit function:", error); // Debug log
      toast.error('Error saving sprint: ' + error.message);
      console.log("Error in submit function:", error.message); // Debug log
    }
  };

  const fetchStatusList = async () => {
    getAllSprintStatus()
      .then((res) => {
        setStatusList(res);
        // changeField("status", res[0]);
      })
      .catch((err)=> console.log(err));
  }

  useEffect(()=>{
    fetchStatusList();
  },[]);

  useEffect(() => {
    if(Object.keys(oldData).length > 0){
      setSprintData({
        name: oldData.name,
        status: oldData.status ? oldData.status : SprintStatus[0],
        projectId: oldData.projectId,
        startDate: oldData.startDate ? new Date(oldData.startDate) : null,
        endDate: oldData.endDate ? new Date(oldData.endDate) : null,
      })
    }
  }, [oldData]);

  return (
    <form onSubmit={handleSubmit(submit)} className='min-h-[35vh] h-max flex flex-col justify-between'>  
      <div>
        <div className='grid grid-cols-4 gap-4 mb-3'>
          <div className='col-span-3 relative'>
            {/*{ sprintData.name.length > 0 &&*/}
            {/*  <label className="absolute top-[-8px] left-3 bg-white px-2 text-xs font-semibold text-gray-400">*/}
            {/*    {t("SPRINT.SPRINT_NAME")}*/}
            {/*  </label>*/}
            {/*}*/}
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("SPRINT.SPRINT_NAME")} <span className="text-red-500"> *</span></label>
            </div>
            <input
              placeholder={t("SPRINT.SPRINT_NAME")}
              className='text-sm border py-2 px-3 h-10 rounded-lg w-full'
              value={sprintData.name}
              onChange={(e) => changeField("name", e.target.value)}
            />
          </div>
          <div className="relative flex flex-col justify-between">
            <div className='mb-0.5 ml-3'>
              <label className='text-xs font-semibold text-gray-500'>{t("SPRINT.SPRINT_STATUS")} </label>
            </div>
            <div className='relative min-h-10 flex items-center'>
              <button
                onClick={() => setIsStatusSelectOpen(true)}
                className={cx(styles.status)}
                style={{
                  color: EnumsColors[sprintData.status].text,
                  backgroundColor: EnumsColors[sprintData.status].bg
                }}
              >
                • {t(`COMMON.TASK_STATUS.${sprintData.status}`)}
              </button>
              {
                isStatusSelectOpen &&
                <div className={styles.floatStatusMenu} ref={statusSelectRef}>
                  <ul>
                    <li onClick={() => setIsStatusSelectOpen(false)}
                        className={styles.currStatus}>{t(`COMMON.TASK_STATUS.${sprintData.status}`)}</li>
                    {
                      SprintStatus.map((it) =>
                        <li
                          key={it} onClick={() => clickStatus(it)}
                          className={styles.status}
                          style={{color: EnumsColors[it].text, backgroundColor: EnumsColors[it].bg}}
                        >
                          • {t(`COMMON.TASK_STATUS.${it}`)}
                        </li>
                      )
                    }
                  </ul>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-3 mb-3">
          <div className="col-span-2">
          <div className="mb-0.5 ml-3">
              <label className="text-xs font-semibold text-gray-500">
                {t('SPRINT.SPRINT_CREATE_MODAL.SPRINT_DATE_RANGE')}<span className="text-red-500"> *</span>
              </label>
            </div> 
            <Controller
              control={control}
              name="dateRange"
              rules={{
                validate: (value) =>
                  value?.[0] && value?.[1] ? true : t("COMMON.REQUIRED_ERROR"),
              }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <div>
                  <DatePicker
                    selected={value?.[0]}  
                    onChange={(dates) => {
                      onChange(dates || []);  
                      console.log("dates:", dates);
                    }}
                    startDate={value?.[0]}
                    endDate={value?.[1]}
                    selectsRange
                    dateFormat="dd/MM/yyyy"
                    placeholderText={`${t(
                      "SPRINT.SPRINT_CREATE_MODAL.SPRINT_START_DATE"
                    )} - ${t("SPRINT.SPRINT_CREATE_MODAL.SPRINT_END_DATE")}`}
                    className="h-10 px-3 border rounded-xl text-sm text-gray-700 w-full"
                    isClearable
                    wrapperClassName="w-full"
                    minDate={new Date()}
                  />
                  {error && <span className="text-red-500 text-xs font-semibold block">{error.message}</span>}
                </div>
              )}
            />
          </div>
        </div>
      </div>
      <div className='mt-4 flex items-center gap-2 p-2 bg-blue-50 text-blue-600 rounded-lg border border-blue-200'>
        <IoMdInformationCircleOutline  className="w-5 h-5 text-blue-500 font-bold" />
        <p className='text-xs'>
          Les champs avec un <span className='text-red-500'>*</span> à côté sont obligatoires.
        </p>
      </div>
      <div className='mt-6 flex items-center justify-end min-w-[40vw]'>
        {!loading &&
          <button onClick={() => submit()} className={styles.mainBtn}>
            {t("COMMON.SAVE")}
          </button>
        }
        {loading &&
          <div className="flex items-center justify-center px-4 w-28 h-10 bg-purple-light rounded-lg">
            <div className="loader"/>
          </div>
        }
      </div>
    </form>
  )
}

export default function SprintView({projectId, newFetch}) {

  const {id} = useParams();
  const {t} = useTranslation();

  const [isTaskModalOpen, setIsTaskModalOpen] = useState(false);
  const [taskData, setTaskData] = useState({});
  const [isEditSprintModalOpen, setIsEditSprintModalOpen] = useState(false);
  const [editSprintModalData, setEditSprintModalData] = useState({});

  const [columnsData, setColumnsData] = useState([]);
  const [tasks, setTasks] = useState({});

  const openTaskModal = (task) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsTaskModalOpen(true);
    setTaskData(task);
  }
  const closeTaskModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsTaskModalOpen(false);
    setTaskData({});
    fetchTasks();
  }

  const openEditSprintModal = (data) => {
    document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    setIsEditSprintModalOpen(true);
    setEditSprintModalData(data);
  }
  const closeEditSprintModal = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'auto';
    setIsEditSprintModalOpen(false);
    setEditSprintModalData({});
    fetchSprints();
  }

  const categorizeTasks = (tasks, columns) => {
    const categorizedTasks = columns.reduce((acc, column, index) => {
      acc[column.id] = tasks.filter(task => task.sprint.id === column.id);
      return acc;
    }, {});
    return categorizedTasks;
  };

  const fetchSprints = async () => {
    const sprintData = {
      "keyWord": "",
      "projectId": projectId
    }
    getAllSprint(sprintData)
      .then((res) => {
        let sprints = res.sort((a, b) => a.orderIndex - b.orderIndex);
        setColumnsData(sprints);
      })
      .catch((err) => console.log(err));
  }

  const fetchTasks = async () => {
    const tasksData = {
      "keyWord": "",
      "projectId": projectId
    }
    const params = {
      page: 1,
      size: 10,
      sort: `id,desc`,
    };

    let allTasks = [];
    await getAllTasks(tasksData, params)
      .then((res) => {
        allTasks.push(...res.content);
      })
      .catch((err) => console.log(err));

    await getAllFinishedTasks(tasksData, params)
      .then((res) => {
        allTasks.push(...res.content);
      })
      .catch((err) => console.log(err));

    setTasks(categorizeTasks(allTasks, columnsData));
  }

  useEffect(() => {
    fetchSprints();
  }, [newFetch]);

  useEffect(() => {
    if (columnsData.length > 0) {
      fetchTasks();
    }
  }, [columnsData]);

  return (
    <div>
      <ToastContainer autoClose={1000} />
      <div className='mt-6 h-full'>
        <KanbanView
          fetchSprints={fetchSprints}
          fetchTasks={fetchTasks}
          openTaskModal={openTaskModal}
          openEditSprintModal={openEditSprintModal}
          projectId={projectId}
          columnsData={columnsData}
          setColumnsData={setColumnsData}
          tasks={tasks}
          setTasks={setTasks}
        />
      </div>
      <Modal isOpen={isEditSprintModalOpen} title={t('SPRINT.SPRINT_EDITED')} close={closeEditSprintModal}>
        <EditSprintFunction close={closeEditSprintModal} oldData={editSprintModalData} projectId={projectId}/>
      </Modal>
      <AnimatePresence>
        {
          isTaskModalOpen &&
          <SideModal isOpen={isTaskModalOpen} title="side modal" close={closeTaskModal}>
            <TaskModal close={closeTaskModal} task={taskData} projectId={id} />
          </SideModal>
        }
      </AnimatePresence>
    </div>
  )
}